<template>
  <div class="p-6 content">
    <div class="flex relative">
      <h2 class="my-auto text-00243D text-2xl text-center" style="font-family: Maersk-Regular">Platform Search</h2>
      <Input
        :suggestions="service.suggestions.value?.hits?.hits"
        :showSuggestions="service.showSuggestions.value"
        class="block absolute left-0 right-0 ml-2 w-full md:w-full lg:w-1/2 ml-auto mr-auto"
        v-model="query"
        :items="service.items"
        v-on:search="onSearch" />
      <!-- <span>{{ service.searchOverview.value }}</span> -->
    </div>

    <div class="mt-8 w-full h-px bg-B2B2B2"></div>

    <!-- Loading  -->
    <Loader class="ml-10" :loading="service.loading.value" />

    <!-- No results -->
    <div class="mt-10" v-if="service.searchData?.value?.Items?.length == 0">
      <span>No results!</span>
    </div>

    <div class="mt-4" v-if="service.searchData?.value?.Items?.length > 0">
      <span class="block text-sm">{{ service.searchOverview.value }}</span>
    </div>

    <!-- Search Result -->
    <div class="mt-2">
      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.CUSTOMER_ORDERS]"
        :data="service.customerOrders.value"
        v-if="!service.loading || service.customerOrders.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.CUSTOMER_ORDERS"
        :dataCategory="'Customer Orders'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.SHIPPER_BOOKINGS]"
        :data="service.shipperBookings.value"
        v-if="!service.loading || service.shipperBookings.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.SHIPPER_BOOKINGS"
        :dataCategory="'Shipper Bookings'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.CARGO_STUFFINGS]"
        :data="service.cargoStuffings.value"
        v-if="!service.loading || service.cargoStuffings.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.CARGO_STUFFINGS"
        :dataCategory="'Cargo Stuffings'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.RECEIVINGS]"
        :data="service.receivings.value"
        v-if="!service.loading || service.receivings.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.RECEIVINGS"
        :dataCategory="'Receivings'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.CARRIER_BOOKING]"
        :data="service.carrierBooking.value"
        v-if="!service.loading || service.carrierBooking.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.CARRIER_BOOKING"
        :dataCategory="'Carrier Bookings'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.SHIPPING_INSTRUCTIONS]"
        :data="service.shippingInstructions.value"
        v-if="!service.loading || service.shippingInstructions.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.SHIPPING_INSTRUCTIONS"
        :dataCategory="'Shipping Instructions'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.VERIFIED_GROSS_MASS]"
        :data="service.verifiedGrossMass.value"
        v-if="!service.loading || service.verifiedGrossMass.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.VERIFIED_GROSS_MASS"
        :dataCategory="'Verified Gross Mass'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.DOCUMENTS]"
        :data="service.documents.value"
        v-if="!service.loading || service.documents.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.DOCUMENTS"
        :dataCategory="'Documets'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.FORWARDERS_CARGO_RECEIPT]"
        :data="service.forwardersCargoReceipt.value"
        v-if="!service.loading || service.forwardersCargoReceipt.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.FORWARDERS_CARGO_RECEIPT"
        :dataCategory="'Forwarders Cargo Receipt'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.CARRIER_EVENTS]"
        :data="service.carrierEvents.value"
        v-if="!service.loading || service.carrierEvents.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.CARRIER_EVENTS"
        :dataCategory="'Carrier Events'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.CASES]"
        :data="service.cases.value"
        v-if="!service.loading || service.cases.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.CASES"
        :dataCategory="'Cases'" />

      <DataTable
        :tableColumns="tableColumns[PlatformSearchIndexes.CONTAINERS]"
        :data="service.containers.value"
        v-if="!service.loading || service.containers.value.length > 0"
        :defaultExpanded="service.defaultExpanded.value == PlatformSearchIndexes.CONTAINERS"
        :dataCategory="'Containers'" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { onMounted, ref, watch } from 'vue';
import Select from '@components/select/Select.vue';
import Input from './input/Input.vue';
import PlatformSearchService, { PlatformSearchIndexes } from './PlatformSearchService';
import { IndexItem } from './PlatformSearchTypes';
import Loader from '@/components/loader/Loader.vue';
import Modal from './modal/Modal.vue';
import DataTable from '@features/platform-search/data-table/DataTable.vue';
import { tableColumns } from './PlatformSearchConstants';

export default {
  components: {
    Select,
    Input,
    Loader,
    Modal,
    DataTable,
  },
  setup() {
    const service = new PlatformSearchService();

    const query = ref<string>();

    const searchIndex = ref<IndexItem>(service.items[0]);

    watch(query, () => {
      service.autocomplete(query.value);
    });

    const onSearch = async ({ queryCategory, queryText }) => {
      if (queryText != '') {
        switch (queryCategory) {
          case PlatformSearchIndexes.ALL:
            {
              await service.queryAll(queryCategory, queryText);
            }
            break;
          default: {
            await service.queryAny(queryCategory, queryText);
          }
        }
      }
    };

    return {
      query,
      onSearch,
      searchIndex,
      service,
      tableColumns,
      PlatformSearchIndexes,
    };
  },
};
</script>

<style scoped>
.content {
  height: calc(100vh - 65px);
}
</style>