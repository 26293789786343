class PathUtils {
  getDynamicPathPattern(url, placeholder): string {
    if (url === '/') return '/';

    // This part matches a string that starts with a forward slash /,
    // followed by one or more alphanumeric or special characters. These special
    // characters are a non-lowercase alphabetic character, a digit, percent %,
    // exclamation mark !, dot ., underscore _, open or closed parenthesis ( ), hyphen -.
    const pattern1 = '/(?=[^a-z]*[A-Z\\d])[A-Za-z\\d%!._()-]+';
  
    // This part matches a string that starts with a forward slash /, followed by
    // zero or more alphanumeric or special characters. These special characters are a
    // non-lowercase alphabetic character, a digit, percent %, exclamation mark !, dot .,
    // underscore _, open or closed parenthesis ( ), hyphen -, followed by one or more digits,
    // and again followed by zero or more alphanumeric or special characters, and this pattern can
    // repeat one or more times if it is prefixed by a hyphen -.
    const pattern2 = '/([A-Za-z\\d%!._()-]*\\d+[A-Za-z\\d%!._()-]*)';
  
    const regex = new RegExp(pattern1 + '|' + pattern2, 'g');
  
    return url.replace(regex, `/${placeholder}`).replace(/\/$/, '');
  };
}

const puInstance = new PathUtils();
export default puInstance;