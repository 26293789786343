<template>
  <Listbox as="div" v-model="selected">
    <div class="mt-1 relative">
      <ListboxButton class="relative w-full bg-white border border-B2B2B2 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:border-B2B2B2">
        <span class="flex items-center">
          <span class="ml-1 block truncate">{{ selected.text }}</span>
        </span>
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
          <ListboxOption as="template" v-for="item in items" :key="item.id" :value="item" v-slot="{ selected }">
            <li :class="['hover:bg-42B0D5 hover:text-white text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9']">
              <div class="flex items-center">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-1 block truncate']">
                  {{ item.text }}
                </span>
              </div>

              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { ref, watch } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    items: { type: Array },
    modelValue: { type: Object },
  },
  setup(props, context) {
    const selected = ref(props?.items?.length > 0 ? props.items[0] : undefined);

    watch(selected, () => {
      context.emit('update:modelValue', selected.value);
    });
    return {
      selected,
    };
  },
};
</script>