<template>
  <div class="px-6 md:px-12 lg:px-24 py-12 lg:flex bg-00243D text-white">
    <div class="md:flex max-w-7xl mx-auto">
      <div class="md:w-2/3 lg:pr-4 my-auto">
        <h1 class="text-4xl" style="font-family: Maersk-Headline-Light">Welcome to SCM Platform</h1>
        <p class="pt-4">
          " Our customers look to us for innovative, industry-leading solutions, new ways to connect and make it simpler to do business with Maersk. The SCM Platform is going to be a milestone proof
          point in our ‘global integrator’ story, and one that is going to unlock a lot of growth" - Martin Holme, Global Head of Lead Logistics
        </p>
        <div class="md:flex mt-6">
          <button class="py-2 px-4 mr-4 rounded-md bg-42B0D5" v-on:click="NavigationUtils.navigateExternal(c3)">Register new customer</button>
          <button class="py-2 px-4 mt-4 md:mt-0 rounded-md bg-00243D border border-white" v-on:click="navigateToDashboard">NSCP Dashboard</button>
        </div>
      </div>
      <div class="md:w-1/3 lg:pl-4 my-auto">
        <img class="w-full mt-10 lg:mt-0" style="border-radius: 70px" src="@assets/maersk-1.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { c3 } from '@/AppConfig';
import ROUTER_PATH from '@/router/router-path';
import NavigationUtils from '@utils/Navigation';

export default {
  setup() {
    return {
      c3,
      NavigationUtils,
    };
  },
  methods: {
    navigateToDashboard() {
      this.$router.push(ROUTER_PATH.Dashboard.path);
    },
  },
};
</script>