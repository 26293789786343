<template>
  <div class="text-center mt-4" v-if="loading" title="Loading...">
    <div style="border-top-color: transparent; border-left-color: transparent" class="mx-auto w-6 h-6 border-4 border-42B0D5 border-solid rounded-full animate-spin"></div>
    <span class="text-sm">{{ message }}</span>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';
export default {
  name: 'Spinner',
  props: ['loading', 'color'],
  setup(props) {
    const message = ref('Loading...');

    onMounted(() => {
      setTimeout(() => {
        message.value = 'Taking longer than usual. Please wait...';
      }, 10000);
    });

    watch(
      () => props.loading,
      () => {
        if (props.loading) {
          message.value = 'Loading...';
          setTimeout(() => {
            message.value = 'Taking longer than usual. Please wait...';
          }, 10000);
        }
      }
    );

    return {
      message,
    };
  },
};
</script>

<style scoped>
.loader {
  min-height: 500px;
}
</style>
