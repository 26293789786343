// @ts-nocheck
import { RumInit,  } from '@maersk-global/telemetry-web-sdk';
import { MOP_APP_NAME, MOP_API_KEY } from '@/AppConfig';
import PathUtils from "@utils/PathUtils";

/**
 * We have had to do @ts-nocheck on the file as there are incorrect type mappings
 * in telemetry-web-sdk. We don't want to add redundant code because of those
 * false warnings.
 */

type PensieveInstance = {
  api: {
    setUser: Function;
    pushEvent: Function;
  }
}

class PensieveService {
    pensieveInstance: PensieveInstance;

    constructor() {
        this.pensieveInstance = {
          api: {
            setUser: (user) => { console.debug(user); },
            pushEvent: (event, path) => { console.debug(event, path) },
          }
        }
    }

    initialize() {
      if (MOP_APP_NAME && MOP_API_KEY) {
        this.pensieveInstance = RumInit({
          app: {
            name: MOP_APP_NAME,
          },
          apiKey: MOP_API_KEY,
      
          ignoreUrls: [`http://localhost:3000/`],
      
          // Add Trace ID only for API calls to instrument the flow end-to-end
          propagateTraceHeaderCorsUrls: [
            /.*api.*.maersk.com.*/
          ],
      
          sso_user: true,
      
          instrumentations: {
            documentload: true,
            fetch: true,
            xhr: true,
            webvitals: true,
            errors: true,
          },

          metas: [
            () => ({
              page: {
                url: window.location.href,
                attributes: {
                  pattern: PathUtils.getDynamicPathPattern(window.location.pathname, '[ID]'),
                },
              },
            }),
          ],
        });
      }
    }

    setUser(userOid: string) {
      this.pensieveInstance.api.setUser({
        id: userOid,
      })
    }

    firePageView(pathname: string) {
      this.pensieveInstance.api.pushEvent(
        'page_view',
        {
          path: PathUtils.getDynamicPathPattern(
            pathname,
            '[ID]',
          ),
        },
      );
    }

    fireEvent(event, options) {
      this.pensieveInstance.api.pushEvent(
        event,
        options,
      );
    }
}

const pensieveInstance = new PensieveService();
export default pensieveInstance;
