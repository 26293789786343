<template>
  <!-- div to render swagger-ui -->
  <div class="-mt-10" id="swagger"></div>
</template>

<script lang="ts">
import SwaggerUI from 'swagger-ui';
import MsalService from '../../service/MsalService';

export default {
  name: 'SwaggerUI',
  props: ['data'],
  /**
   * Watch of props changes
   */
  watch: {
    data(newValue) {
      SwaggerUI({
        spec: newValue,
        dom_id: '#swagger',
      });
    },
  },
  async mounted() {
    const ui = SwaggerUI({
      spec: this.data,
      dom_id: '#swagger',
      requestInterceptor: async (req) => {
        if (!req.headers.Authorization) {
          req.headers.Authorization = `Bearer ${await MsalService.getAccessToken()}`;
          if (!req.headers['Content-Type'] || !req.headers['content-type']) {
            req.headers['Content-Type'] = 'application/json';
          }
        }
        return req;
      },
    });
    ui.preauthorizeApiKey('Authentication', await MsalService.getAccessToken());
  },
};
</script>
