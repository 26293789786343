<template>
  <div>
    <SiteHeader :pageTitle="`Platform Health`" />
    <div class="md:flex content" data-dev-hint="container">
      <input type="checkbox" id="menu-open" class="hidden" />
      <Sidebar class="md:hidden" />
      <main class="overflow-hidden flex-1">
        <div class="">
          <div class="bg-white">
            <nav class="flex flex-col sm:flex-row">
              <button
                v-bind:class="`text-gray-600 py-4 px-6 block hover:text-42B0D5 focus:outline-none font-medium ${currentTab == 1 ? 'text-42B0D5 border-b-2 border-42B0D5' : ''}`"
                v-on:click="viewEngineeringMetrics">
                Engineering Metrics
              </button>
              <button
                v-if="!EnvironmentUtils.isProd()"
                v-bind:class="`flex text-gray-600 py-4 px-6 block hover:text-42B0D5 focus:outline-none ${currentTab == 2 ? 'text-42B0D5 border-b-2 border-42B0D5' : ''}`"
                v-on:click="viewtestReports">
                Test Reports
              </button>
              <button
                v-bind:class="`flex text-gray-600 py-4 px-6 block hover:text-42B0D5 focus:outline-none ${currentTab == 3 ? 'text-42B0D5 border-b-2 border-42B0D5' : ''}`"
                v-on:click="viewIncidentsMetrics">
                Incidents <img class="w-4 ml-2 my-auto" src="@/assets/external-link-1.svg" alt="" srcset="" />
              </button>
            </nav>
          </div>
          <SupersetDashboard v-if="currentTab == 1" class="cus" :dashboardId="engineeringMetricsDashboardId" :containTabs="true" />
          <ReportPortalDashboard v-if="currentTab == 2" :containTabs="true" />
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { ref } from 'vue';
import SiteHeader from '@components/site-header/SiteHeader.vue';
import PHOperationalMonitors from '@features/platform-health/operational-monitors/PHOperationalMonitors.vue';
import PHQualityMetrics from '@features/platform-health/quality-metrics/PHQualityMetrics.vue';
import SiteFooter from '@components/site-footer/SiteFooter.vue';
import Sidebar from '@features/sidebar/Sidebar.vue';
import SupersetDashboard from '@features/superset-dashboard/SupersetDashboard.vue';
import { engineeringMetricsDashboardId, incidents, bstReports } from '@/AppConfig';
import ReportPortalDashboard from '@features/report-portal-dashboard/ReportPortalDashboard.vue';
import EnvironmentUtils from '@utils/EnvironmentUtils';
import NavigationUtils from '@utils/Navigation';

export default {
  components: {
    SiteHeader,
    PHOperationalMonitors,
    PHQualityMetrics,
    SiteFooter,
    Sidebar,
    SupersetDashboard,
    ReportPortalDashboard,
  },
  setup() {
    const tabsTypes = {
      engineeringMetrics: '1',
      testReports: '2',
    };

    const currentTab = ref(tabsTypes.engineeringMetrics);

    const viewEngineeringMetrics = () => {
      currentTab.value = tabsTypes.engineeringMetrics;
    };

    const viewIncidentsMetrics = () => {
      NavigationUtils.navigateExternal(incidents);
    };

    const viewtestReports = () => {
      currentTab.value = tabsTypes.testReports;
      console.log('calling');
    };

    return {
      currentTab,
      engineeringMetricsDashboardId,
      viewIncidentsMetrics,
      viewtestReports,
      viewEngineeringMetrics,
      EnvironmentUtils,
    };
  },
  mounted() {
    document.title = `SCP | Dashboard | Platform Health`;
  },
};
</script>