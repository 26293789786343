import { createApp } from 'vue'
import App from './App.vue'
import { MOP_APP_NAME, MOP_API_KEY } from '@/AppConfig';
import './styles/global.css'
import './styles/developer-hub.css'
import './styles/fonts.scss'
import router from './router/index';
import { } from './extensions/String';
import 'swagger-ui/dist/swagger-ui.css';
import { vue3Debounce } from 'vue-debounce'
import PathUtils from '@utils/PathUtils';
import PensieveInstance from "@/service/PensieveService";

PensieveInstance.initialize();

createApp(App).use(router).directive('debounce', vue3Debounce({ lock: true })).mount('#app')