import PensieveInstance from '@/service/PensieveService';

class NavigationUtils {
  navigateExternal(link) {
    PensieveInstance.fireEvent(
      'external_link_navigate',
      {
        to: link,
      }
    );
    window.open(link, '_blank');
  }
}

const nuInstance = new NavigationUtils();
export default nuInstance;