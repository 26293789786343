import { PlatformSearchIndexes } from "./PlatformSearchService"
import { TableColumns, Tables } from './PlatformSearchTypes'

export const tableColumns: Tables = {}

tableColumns[PlatformSearchIndexes.SHIPPING_INSTRUCTIONS] = [
    {
        columnName: 'ShippingInstructionNumber',
        displayName: 'SI Number'
    },
    {
        columnName: 'ShippingInstructionStatus',
        displayName: 'Status'
    },
    {
        columnName: 'ServiceType',
        displayName: 'Service Type'
    },
    {
        columnName: 'CreatedAt',
        displayName: 'Created At'
    }
]

tableColumns[PlatformSearchIndexes.CARGO_STUFFINGS] = [
    {
        columnName: 'CargoStuffingNumber',
        displayName: 'CS Number'
    },
    {
        columnName: 'StuffedDatetime',
        displayName: 'StuffedDatetime'
    },
    {
        columnName: 'CargoStuffingStatus',
        displayName: 'Status'
    },
    {
        columnName: 'CargoStuffingType',
        displayName: 'Stuffing Type'
    }
]

tableColumns[PlatformSearchIndexes.RECEIVINGS] = [
    {
        columnName: 'ShipperBookingNumber',
        displayName: 'SB Number'
    },
    {
        columnName: 'ShipperBookingStatus',
        displayName: 'Status'
    },
    {
        columnName: 'CreateTimestamp',
        displayName: 'CreateTimestamp'
    }
]

tableColumns[PlatformSearchIndexes.CARRIER_BOOKING] = [
    {
        columnName: 'CarrierBookingPublicId',
        displayName: 'Booking Number'
    },
    {
        columnName: 'ConsigneeName',
        displayName: 'Consignee'
    },
    {
        columnName: 'ShipperName',
        displayName: 'Shipper'
    },
    {
        columnName: 'CarrierBookingStatus',
        displayName: 'Status'
    },
    {
        columnName: 'ProductType',
        displayName: 'Product'
    }
]

tableColumns[PlatformSearchIndexes.VERIFIED_GROSS_MASS] = [
    {
        columnName: 'VerifiedGrossMassNumber',
        displayName: 'VGM Number'
    },
    {
        columnName: 'VerifiedGrossMassStatus',
        displayName: 'Status'
    },
    {
        columnName: 'ConsigneeName',
        displayName: 'Consignee'
    },
    {
        columnName: 'CarrierName',
        displayName: 'Carrier'
    },
    {
        columnName: 'ProductType',
        displayName: 'Product'
    }
]

tableColumns[PlatformSearchIndexes.SHIPPER_BOOKINGS] = [
    {
        columnName: 'ShipperBookingNumber',
        displayName: 'Booking Number'
    },
    {
        columnName: 'ShipperBookingStatus',
        displayName: 'Booking Status'
    },
    {
        columnName: 'CreateSource',
        displayName: 'Product'
    }
]

tableColumns[PlatformSearchIndexes.CUSTOMER_ORDERS] = [
    {
        columnName: 'CustomerOrderNumber',
        displayName: 'Order Number'
    },
    {
        columnName: 'CustomerOrderStatus',
        displayName: 'Order Status'
    },
    {
        columnName: 'CustomerOrderBookedStatus',
        displayName: 'Booking Status'
    },
    {
        columnName: 'CreateSource',
        displayName: 'Product'
    }
]

tableColumns[PlatformSearchIndexes.DOCUMENTS] = [
    {
        columnName: 'DocumentName',
        displayName: 'Document Name'
    },
    {
        columnName: 'DocumentTypeName',
        displayName: 'Document Type'
    },
    {
        columnName: 'WorkOrderType',
        displayName: 'Work Order Type'
    },
    {
        columnName: 'WorkOrderReference',
        displayName: 'Work Order Reference'
    },
    {
        columnName: 'Status',
        displayName: 'Status'
    }
]

tableColumns[PlatformSearchIndexes.FORWARDERS_CARGO_RECEIPT] = [
    {
        columnName: 'ForwardersCargoReceiptNumber',
        displayName: 'FCR Number'
    },
    {
        columnName: 'ForwardersCargoReceiptStatus',
        displayName: 'FCR Status'
    },
    {
        columnName: 'ShipperName',
        displayName: 'Shipper'
    },
    {
        columnName: 'ConsigneeName',
        displayName: 'Consignee'
    },
    {
        columnName: 'TransportMode',
        displayName: 'Transport Mode'
    }
]

tableColumns[PlatformSearchIndexes.CARRIER_EVENTS] = [
    {
        columnName: 'CarrierBillOfLading',
        displayName: 'CBL No.'
    },
    {
        columnName: 'Container',
        displayName: 'Container'
    },
    {
        columnName: 'ShippingInstructionNumber',
        displayName: 'SI Number'
    },
    {
        columnName: 'EventTriggerName',
        displayName: 'Event Name'
    },
    {
        columnName: 'EventTypeName',
        displayName: 'Event Type'
    },

]

tableColumns[PlatformSearchIndexes.CASES] = [
    {
        columnName: 'CaseNumber',
        displayName: 'Case Number'
    },
    {
        columnName: 'CaseType',
        displayName: 'Type'
    },
    {
        columnName: 'IssueType',
        displayName: 'Issue'
    },

    {
        columnName: 'Status',
        displayName: 'Status'
    },
    {
        columnName: 'ConsigneeName',
        displayName: 'Consignee'
    },
]

tableColumns[PlatformSearchIndexes.CONTAINERS] = [
    {
        columnName: 'ContainerNumber',
        displayName: 'Container Number'
    },
    {
        columnName: 'ContainerDetails',
        columnName2: 'CargoStuffingNumber',
        displayName: 'Cargo Stuffing Number'
    },
    {
        columnName: 'ContainerDetails',
        columnName2: 'ShippingInstructionNumber',
        displayName: 'Shipping Instruction Number'
    },
    {
        columnName: 'ContainerDetails',
        columnName2: 'ServiceCode',
        displayName: 'Service Code'
    }
]