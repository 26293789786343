import HttpRequest from './HttpService';
import { API_BASE_URL, sciConsumer } from '@/AppConfig'
import { SCITokenData } from '@/features/power-bi/report/PowerBIReportTypes';

const GET_TOKEN = API_BASE_URL + '/business-intelligence/homepage/rls-access-token'

class Service {
    async getToken(params: object): Promise<SCITokenData> {
        const response = new HttpRequest().makeRequest({
            url: GET_TOKEN,
            method: 'GET',
            params: params,
            headers: {
                'Application-Key': sciConsumer,
                'Api-Version': 'v1'
            }
        });
        return response;
    }
}

const SCIService = new Service()
export default SCIService;