<template>
  <section id="container" style="height: 750px" />
</template>

<script lang="ts">
import Vue, { onMounted } from 'vue';
import * as pbi from 'powerbi-client';
import PowerBIReportService from './PowerBIReportService';

export default {
  props: ['reportId', 'groupId'],
  setup(props) {
    onMounted(async () => {
      const service = new PowerBIReportService();
      await service.getToken(props.groupId, props.reportId);
      let embedConfiguration = {
        accessToken: service.token.value,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${props.reportId}&groupId=${props.groupId}`,
        id: props.reportId,
        permissions: pbi.models.Permissions.All,
        tokenType: pbi.models.TokenType.Embed,
        type: 'report',
      };

      let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      const dashboardContainer = document.getElementById('container');
      const dashboard = powerbi.embed(dashboardContainer, embedConfiguration);

      dashboard.off('loaded');
      dashboard.off('rendered');
      dashboard.on('error', function (e) {
        console.log(e);
      });
    });
  },
};
</script>