<template>
  <div>
    <Loader :loading="service.serviceSpecLoading.value" />
    <Error :error="service.error.value" />
    <SwaggerUI :data="service.serviceSpec?.value?.swaggerSpec" v-if="!service.serviceSpecLoading.value && service.serviceSpec?.value?.swaggerSpec" />
  </div>
</template>

<script lang="ts">
import SwaggerUI from '@/components/swagger-ui/SwaggerUI.vue';
import ServiceDocumentationService from './ServiceDocumentationService';
import { watch } from '@vue/runtime-core';
import Loader from '@/components/loader/Loader.vue';
import Error from '@components/error/Error.vue';

export default {
  name: 'ServiceApiDocumentation',
  props: ['capabilityId', 'serviceId'],
  components: {
    SwaggerUI,
    Loader,
    Error,
  },
  setup(props) {
    // Creating service instance
    const service = new ServiceDocumentationService();

    watch(
      () => props.serviceId,
      () => {
        service.getServiceSpec(props.capabilityId, props.serviceId);
      }
    );

    return {
      service,
    };
  },
};
</script>