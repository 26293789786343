<script lang="ts">
import { onMounted } from '@vue/runtime-core';
import SGService from './service/SGService';
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
export default {
  components: {},
  setup() {
    onMounted(async () => {
      await SGService.Instance.getDashboardViewerToken('15');
    });
  },
};
</script>

<template>
  <router-view class="main-content" />
</template>

<style>
#app {
  font-family: 'Maersk-Regular';
}
</style>
