import MsalService from "@/service/MsalService";
import SCIService from "@/service/SCIService";
import { ref } from "vue";
import { SCITokenData } from "./PowerBIReportTypes";

class PowerBIReportService {

    token = ref<string>(undefined)

    loading = ref(false)

    error = ref<string>(undefined)

    async getToken(workspaceId: string, reportId: string) {
        this.loading.value = true;
        const headers = {
            "EmailAddress": MsalService.getUserProfile().userName,
            "Roles": "Standard user",
            "DashboardType": "other dataset",
            "WorkspaceId": workspaceId,
            "ReportId": reportId,
            "customerName": MsalService.getUserProfile().name,
            "ReportName": "NSCP-Reconciliation",
            "reportCustomer": "DEMO CUSTOMER"
        }
        try {
            this.token.value = (await SCIService.getToken(headers)).output.token
        } catch (error) {
            console.log(error)
            this.error.value = `Error in fetching token. ${error}`;
        }
        this.loading.value = false;
    }
}

export default PowerBIReportService