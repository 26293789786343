import { ref } from "@vue/runtime-core";
import { WorkStreamsType } from "./PHQualityMetricsTypes";

class PHQualityMetricsService {
    qualityMetricsWorkstreams = ref<WorkStreamsType[]>([])

    constructor() {
        this.qualityMetricsWorkstreams.value = [
            {
                name: '1. Business Scenario Testing(BST) Report',
                description: '',
                link: 'https://dev.azure.com/SCMPlatform/Central%20Repo/_test/analytics?definitionId=756&contextType=build',
                image: '../../assets/report-1.png',
            },
            {
                name: '2. Incidents',
                description: '',
                link: 'https://dev.azure.com/SCMPlatform/SCM%20Backlog/_dashboards/dashboard/7a3a04e2-ac3b-4154-b997-85bef9d321dd',
                image: '../../assets/report-1.png',
            },
            {
                name: '3. Engineering Metrics',
                description: '',
                link: '/dashboards/engineering-metrics',
                image: '../../assets/report-1.png',
            },
        ];
    }
}

export default PHQualityMetricsService