<template>
  <div class="px-6 md:px-12 lg:px-24 py-12">
    <div class="max-w-7xl mx-auto">
      <label class="text-4xl text-00243D" style="font-family: Maersk-Headline">Know More on SCP</label>
      <p class="mt-5 text-545454">More information, videos, roadmaps, FAQS, etc. on New SCM platform and its product offerings.</p>
      <button class="mt-6 py-2 px-4 mr-2 rounded-md bg-42B0D5 text-white" v-on:click="NavigationUtils.navigateExternal(knowMoreLink)">Know more</button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ROUTER_PATH from '@/router/router-path';
import { knowMoreLink } from '@/AppConfig';
import PensieveInstance from '@/service/PensieveService';
import NavigationUtils from '@utils/Navigation';

export default {
  data() {
    return {
      knowMoreLink,
      NavigationUtils,
    }
  },
  methods: {
    navigateToDevhub() {
      this.$router.push(ROUTER_PATH.DeveloperHubHome.path);
    },
  },
};
</script>