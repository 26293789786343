<template>
  <div>
    <SiteHeader :pageTitle="`Dashboards`" />
    <div class="md:flex content" data-dev-hint="container">
      <input type="checkbox" id="menu-open" class="hidden" />
      <Sidebar class="md:hidden" />
      <main class="overflow-hidden flex-1">
        <div class="">
          <SupersetDashboard :dashboardId="globalSearchDashboardId" />
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import SupersetDashboard from '@features/superset-dashboard/SupersetDashboard.vue';
import { globalSearchDashboardId } from '@/AppConfig';
import SiteHeader from '@components/site-header/SiteHeader.vue';
import SiteFooter from '@components/site-footer/SiteFooter.vue';
import Sidebar from '@features/sidebar/Sidebar.vue';

export default {
  components: {
    SupersetDashboard,
    SiteHeader,
    SiteFooter,
    Sidebar,
  },
  setup() {
    return {
      globalSearchDashboardId,
    };
  },
  mounted() {
    document.title = `SCP | Dashboard | Global Search`;
  },
};
</script>
