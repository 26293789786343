import SGService from "@/service/SGService"
import { ref } from "vue"
import { CapabilityListData, Menu } from "./DeveloperHubSidebarTypes"

class DeverloperHubSidebarService {
    capabilities = ref<CapabilityListData[]>([])

    loading = ref(false)

    searchData = ref([])

    error = ref<string>(undefined)

    selectedCapability = ref<string>('')

    selectedService = ref<string>('')

    selectedWindow = ref<Menu>(Menu.Home)

    internalCollapse = ref<Boolean>(false)

    async getCapabilityList() {
        this.loading.value = true;
        try {
            // eslint-disable-next-line max-len
            this.capabilities.value = await (await SGService.Instance.getCapabilityListData()).filter((item) => item.name !== 'C3');
        } catch (error) {
            console.log(error)
            this.error.value = `Error in fetching capability list.`;
        }
        this.loading.value = false;
    }

    async mapSearchDataFromCapabilities() {
        this.searchData.value = this.capabilities.value.map((capability) => ({
            label: capability.name,
            payload: capability,
        }));
    }
}

export default DeverloperHubSidebarService

