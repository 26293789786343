<template>
  <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
    <span class="block sm:inline">{{ error }}</span>
    <span class="block mt-4">Try:</span>
    <ol>
      <li class="ml-5 list-disc">Refreshing the page.</li>
      <li class="ml-5 list-disc">Logout/Login again.</li>
      <li class="ml-5 list-disc">Allow blocked popups.</li>
    </ol>
  </div>
</template>

<script lang="ts">
export default {
  props: ['error'],
};
</script>
