import SGService from "@/service/SGService"
import { ref } from "vue"
import { DataExplorerResult } from "./DataExplorerTypes"

class DataExplorerService {

    loading = ref(false)

    queryData = ref<DataExplorerResult[]>([])

    error = ref(undefined)

    exampleQuery = "SELECT ConsigneeName, COUNT(ConsigneeName) as OrderCount\nfrom customer_orders\nGROUP BY ConsigneeName\nORDER BY OrderCount DESC"

    async queryDataExplorer(query: string) {
        this.loading.value = true;
        this.error.value = undefined
        this.reset()
        try {
            // eslint-disable-next-line max-len
            this.queryData.value = await (await SGService.Instance.dataExplorerQuery(query));
            console.log(this.queryData.value[0])
        } catch (error) {
            console.log(error)
            this.error.value = error
        }
        this.loading.value = false;
    }

    reset() {
        this.queryData.value = []
    }

    loadExampleQuery() {
        this.queryDataExplorer(this.exampleQuery)
    }
}

export default DataExplorerService