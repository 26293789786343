<template>
  <div class="static">
    <SiteHeader />
    <div class="md:flex content" data-dev-hint="container">
      <input type="checkbox" id="menu-open" class="hidden" />
      <Sidebar class="md:hidden" />
      <main class="overflow-hidden flex-1">
        <div class="">
          <SectionOne />
          <!-- <SectionTwo /> -->
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <SiteFooter />
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import SiteHeader from '@components/site-header/SiteHeader.vue';
import SectionOne from '@pages/sections/SectionOne.vue';
import SectionTwo from '@pages/sections/SectionTwo.vue';
import SectionFour from '@pages/sections/SectionFour.vue';
import SectionFive from '@pages/sections/SectionFive.vue';
import SectionThree from '@pages/sections/SectionThree.vue';
import SiteFooter from '@components/site-footer/SiteFooter.vue';
import Sidebar from '@features/sidebar/Sidebar.vue';
export default {
  components: {
    SiteHeader,
    SectionOne,
    SectionTwo,
    SectionFour,
    SectionThree,
    SectionFive,
    SiteFooter,
    Sidebar,
  },
  mounted() {
    document.title = `SCP | Home`;
  },
};
</script>

<style>
</style>