<template>
  <aside
    id="sidebar"
    v-bind:class="`
      shadow shadow-lg
      bg-white
      ${service.internalCollapse.value ? 'md:w-12' : 'md:w-72'}
      w-full
      left-0
      transform
      md:relative md:translate-x-0
      transition
      duration-200
      ease-in-out
      md:flex md:flex-col md:justify-between
      overflow-y-scroll
      flex-shrink-0
      fixed
      inset-0
      z-50
      md:z-0
    `"
    data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation">
    <div class="flex flex-col" data-dev-hint="optional div for having an extra footer navigation">
      <div class="flex flex-row-reverse items-center md:hidden">
        <label for="menu-open" class="m-2 p-2 focus:outline-none cursor-pointer rounded-md md:hidden bg-white">
          <svg id="menu-open-icon" class="h-6 w-6 transition duration-200 ease-in-out" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg id="menu-close-icon" class="h-6 w-6 transition duration-200 ease-in-out" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </label>
      </div>

      <!-- Navigation -->
      <nav data-dev-hint="main navigation">
        <!-- Home Nav -->
        <nav data-dev-hint="second-main-navigation or footer navigation" class="flex justify-between my-2">
          <button
            v-bind:hidden="service.internalCollapse.value"
            v-on:click="onMenuSelected(Menu.Home)"
            v-bind:class="`relative w-full py-2 transition duration-200 text-left hover:bg-gray-200`"
            title="Developer Hub">
            <span class="px-4 text-lg">Developer Hub</span>
          </button>
          <button class="hidden md:block" v-on:click="toggleInternalCollapse" title="Toggle menu">
            <img v-if="!service.internalCollapse.value" class="h-5 mx-2" src="@/assets/double-left-1.png" alt="" srcset="" />
            <img v-if="service.internalCollapse.value" class="h-5 mx-2 mt-2" src="@/assets/double-right-1.png" alt="" srcset="" />
          </button>
        </nav>

        <div v-bind:class="`${service.internalCollapse.value ? 'md:hidden' : ''}`">
          <!-- Desgin Nav -->
          <nav data-dev-hint="absolute bottom-0 second-main-navigation or footer navigation">
            <label class="px-4" style="font-family: Maersk-Bold" for="heading">Design</label>
            <a href="https://designsystem.maersk.com/" @click.prevent="NavigationUtils.navigateExternal(maerskDesignSystem)" class="flex py-2 px-4 my-2 transition duration-200 hover:bg-gray-200"
              >SCM Design System<img class="w-4 ml-2 my-auto" src="@/assets/external-link-1.svg" alt="" srcset=""
            /></a>
            <a v-bind:href="uiComponents" @click.prevent="NavigationUtils.navigateExternal(uiComponents)" class="flex py-2 px-4 my-2 transition duration-200 hover:bg-gray-200"
              >UI Components<img class="w-4 ml-2 my-auto" src="@/assets/external-link-1.svg" alt="" srcset=""
            /></a>
          </nav>

          <!-- Tools Nav -->
          <nav data-dev-hint="absolute bottom-0 second-main-navigation or footer navigation">
            <!-- Platform Search -->
            <label class="px-4" style="font-family: Maersk-Bold" for="heading">Tools</label>
            <div v-if="!EnvironmentUtils.isProd()">
              <button
                v-on:click="onMenuSelected(Menu.PlatformSearch)"
                v-bind:class="`relative flex w-full py-2 my-2 ${service.selectedWindow.value == Menu.PlatformSearch ? 'bg-B5E0F5' : ''} transition duration-200 hover:bg-gray-200`">
                <div v-bind:class="`${service.selectedWindow.value == Menu.PlatformSearch ? 'block bg-0073AB' : 'hidden'} absolute top-0 bottom-0 w-1`"></div>
                <span class="px-4">Platform Search</span>
              </button>
            </div>

            <div v-if="!EnvironmentUtils.isProd()">
              <button
                v-on:click="onMenuSelected(Menu.DataExplorer)"
                v-bind:class="`relative flex w-full py-2 my-2 ${service.selectedWindow.value == Menu.DataExplorer ? 'bg-B5E0F5' : ''} transition duration-200 hover:bg-gray-200`">
                <div v-bind:class="`${service.selectedWindow.value == Menu.DataExplorer ? 'block bg-0073AB' : 'hidden'} absolute top-0 bottom-0 w-1`"></div>
                <span class="px-4">Data Explorer</span>
              </button>
            </div>

            <div>
              <button
                v-on:click="onMenuSelected(Menu.NSCPTracer)"
                v-bind:href="uiComponents"
                target="_blank"
                v-bind:class="`relative flex w-full py-2 my-2 ${service.selectedWindow.value == Menu.NSCPTracer ? 'bg-B5E0F5' : ''} transition duration-200 hover:bg-gray-200`">
                <div v-bind:class="`${service.selectedWindow.value == Menu.NSCPTracer ? 'block bg-0073AB' : 'hidden'} absolute top-0 bottom-0 w-1`"></div>
                <span class="px-4">Event Tracer</span>
              </button>
            </div>

            <div>
              <button
                v-on:click="onMenuSelected(Menu.DataQuality)"
                v-bind:href="uiComponents"
                target="_blank"
                v-bind:class="`relative flex w-full py-2 my-2 ${service.selectedWindow.value == Menu.DataQuality ? 'bg-B5E0F5' : ''} transition duration-200 hover:bg-gray-200`">
                <div v-bind:class="`${service.selectedWindow.value == Menu.DataQuality ? 'block bg-0073AB' : 'hidden'} absolute top-0 bottom-0 w-1`"></div>
                <span class="px-4">Data Quality</span>
              </button>
            </div>
          </nav>
          <label class="px-4" style="font-family: Maersk-Bold" for="heading">APIs</label>

          <!-- Search  -->
          <div class="flex m-3 px-2 border border-CFCFCF rounded-md">
            <img class="me-2" src="@assets/icons/search.svg" alt="" srcset="" />
            <input
              class="focus:outline-none focus:ring-transparent focus:border-transparent border-transparent bg-transparent outline-none ml-2 text-sm"
              type="text"
              name=""
              id=""
              placeholder="Search"
              v-model="searchText" />
          </div>
          <Loader class="ml-3" :loading="service.loading.value" />
          <Error class="mx-3" :error="service.error.value" />
          <div v-for="capability in service.capabilities.value" v-bind:key="capability.id" v-bind:hidden="!capability.name.toLowerCase().includes(searchText.toLowerCase())">
            <button
              v-bind:class="`w-full ${service.selectedCapability.value == capability.id ? 'bg-B5E0F5' : ''} py-4 flex relative cursor-pointer accordion  transition duration-200 hover:bg-gray-200`">
              <div v-bind:class="`${service.selectedCapability.value == capability.id ? 'block bg-0073AB' : 'hidden'} absolute top-0 bottom-0 w-1`"></div>
              <div class="w-full px-4 flex justify-between">
                <p class="text-left text-base">{{ capability.name.replace(/([a-z0-9])([A-Z])/g, '$1 $2') }}</p>
                <img class="" src="@assets/icons/dropdown.svg" alt="" srcset="" />
              </div>
            </button>
            <div class="panel py-1" v-bind:style="`${service.selectedCapability.value == capability.id ? 'display:block' : 'display:none'}`">
              <button
                v-bind:class="`w-full px-9 p-2 text-left cursor-pointer hover:bg-gray-200 ${service.selectedService.value == apiService.id ? 'font-bold bg-gray-100' : ''}`"
                v-for="apiService in capability.services"
                v-bind:key="apiService.id"
                v-on:click="onServiceSelected(capability.id, apiService.id)">
                {{ apiService.name.replaceAll('-', ' ').replaceAll('_', ' ').replaceAll('v2', ' ').replaceAll('-v2', ' ').replaceAll('_v2', ' ') }}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <SidebarCommon />
  </aside>
</template>

<script lang="ts">
import Vue, { onMounted, ref } from 'vue';
import DeveloperHubSidebarService from './DeveloperHubSidebarService';
import Loader from '@/components/loader/Loader.vue';
import Error from '@components/error/Error.vue';
import SidebarCommon from '@features/sidebar/SidebarCommon.vue';
import { maerskDesignSystem, uiComponents } from '@/AppConfig';
import { Menu } from './DeveloperHubSidebarTypes';
import EnvironmentUtils from '@utils/EnvironmentUtils';
import NavigationUtils from '@utils/Navigation';
import { useRoute, useRouter } from 'vue-router';
import PensieveInstance from '@/service/PensieveService';

export default {
  props: ['onServiceSelected', 'onMenuSelected'],
  components: {
    Loader,
    Error,
    SidebarCommon,
  },
  setup(props, context) {
    const service = new DeveloperHubSidebarService();
    const searchText = ref<string>('');
    const route = useRoute();
    const router = useRouter();

    if (route.query.capabilityId && route.query.serviceId) {
      service.selectedWindow.value = Menu.Api;
      service.selectedCapability.value = route.query.capabilityId as string;
      service.selectedService.value = route.query.serviceId as string;

      context.emit('onServiceSelected', { capabilityId: route.query.capabilityId, serviceId: route.query.serviceId });
    }

    if (route.query.tool) {
      console.log(route.query.tool);
      service.selectedWindow.value = Menu[route.query.tool as string];
      context.emit('onMenuSelected', Menu[route.query.tool as string]);
    }

    onMounted(async () => {
      await service.getCapabilityList();

      var acc = document.getElementsByClassName('accordion');
      var i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {
          this.classList.toggle('active');

          var panel = this.nextElementSibling;
          if (panel.style.display === 'block') {
            panel.style.display = 'none';
          } else {
            panel.style.display = 'block';
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + 'px';
            }
          }
        });
      }
    });

    const onServiceSelected = (capabilityId: string, serviceId: string) => {
      service.selectedWindow.value = Menu.Api;
      service.selectedCapability.value = capabilityId;
      service.selectedService.value = serviceId;
      console.log(service.selectedCapability.value);

      context.emit('onServiceSelected', { capabilityId, serviceId });

      router.replace({ query: { capabilityId: capabilityId, serviceId: serviceId, tool: Menu[Menu.Api] } });
    };

    const onMenuSelected = (menu: Number) => {
      switch (menu) {
        case Menu.NSCPTracer:
          service.selectedWindow.value = Menu.NSCPTracer;
          router.replace({ query: { tool: Menu[Menu.NSCPTracer] } });
          break;
        case Menu.Home:
          service.selectedWindow.value = Menu.Home;
          router.replace({ query: { tool: Menu[Menu.Home] } });
          break;
        case Menu.DataQuality:
          service.selectedWindow.value = Menu.DataQuality;
          router.replace({ query: { tool: Menu[Menu.DataQuality] } });
          break;
        case Menu.PlatformSearch:
          service.selectedWindow.value = Menu.PlatformSearch;
          router.replace({ query: { tool: Menu[Menu.PlatformSearch] } });
          break;
        case Menu.DataExplorer:
          service.selectedWindow.value = Menu.DataExplorer;
          router.replace({ query: { tool: Menu[Menu.DataExplorer] } });
          break;
      }
      context.emit('onMenuSelected', menu);
      service.selectedService.value = '';
      service.selectedCapability.value = '';
    };

    const toggleInternalCollapse = () => {
      service.internalCollapse.value = !service.internalCollapse.value;
    };

    return {
      service,
      onServiceSelected,
      searchText,
      uiComponents,
      maerskDesignSystem,
      onMenuSelected,
      Menu,
      toggleInternalCollapse,
      EnvironmentUtils,
      NavigationUtils,
    };
  },
  methods: {
    titleCase: (str) => {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
  },
};
</script>