<template>
  <nav class="px-3 md:hidden" data-dev-hint="main navigation">
    <div class="py-1">
      <button
        style="font-family: Maersk-Headline"
        v-on:click="navigateToHomePage"
        class="w-full text-left text-lg"
        v-bind:class="`pt-1 h-full flex ${[ROUTER_PATH.Home.name].includes($route.name) ? 'font-bold' : ''}`">
        Home
      </button>
    </div>
    <div class="py-1">
      <button class="w-full text-left" v-bind:class="`pt-1 h-full flex`">
        <div class="w-full flex justify-between">
          <p
            style="font-family: Maersk-Headline"
            v-bind:class="`text-left text-base text-lg ${[ROUTER_PATH.Dashboard.name, ROUTER_PATH.TrendsDashboard.name, ROUTER_PATH.PlatFormHealth.name].includes($route.name) ? 'font-bold' : ''}`">
            Dashboard
          </p>
        </div>
      </button>
      <div class="panel py-2 ml-4">
        <button class="w-full py-2 text-left" v-on:click="navigateToDashboard" v-bind:class="`pt-1 h-full flex`">Customer Statistics</button>
        <button class="w-full py-2 text-left" v-on:click="navigateToPlatformHealth" v-bind:class="`pt-1 h-full flex`">Platform Health</button>
        <button class="w-full py-2 text-left" v-on:click="navigateToTrendsDashboard" v-bind:class="`pt-1 h-full flex`">Trends</button>
      </div>
    </div>
    <div class="py-1">
      <button
        class="w-full text-left"
        v-bind:class="`pt-1 h-full flex text-lg ${[ROUTER_PATH.DeveloperHubHome.name].includes($route.name) ? 'font-bold' : ''}`"
        style="font-family: Maersk-Headline"
        v-on:click="navigateToDeveloperHub">
        Developers
      </button>
    </div>
    <div class="py-1">
      <button class="w-full text-left" v-bind:class="`pt-1 h-full flex`">
        <div class="w-full flex justify-between">
          <p class="text-left text-base text-lg" style="font-family: Maersk-Headline">HUBS</p>
        </div>
      </button>
      <div class="panel py-2 ml-4">
        <a v-bind:href="csHub" class="w-full py-2 text-left" v-bind:class="`pt-1 h-full flex`" @click.prevent="NavigationUtils.navigateExternal(csHub)">Customer Service HUB</a>
        <a class="w-full py-2 text-left" v-bind:href="scmHub" v-bind:class="`pt-1 h-full flex`" @click.prevent="NavigationUtils.navigateExternal(scmHub)">SCM HUB</a>
      </div>
    </div>
    <div class="py-1">
      <button class="w-full text-left" v-on:click="NavigationUtils.navigateExternal(uamAccess)" v-bind:class="`pt-1 h-full flex text-lg`" style="font-family: Maersk-Headline">UAM Access</button>
    </div>
    <div class="py-1">
      <p class="text-left text-base text-lg" style="font-family: Maersk-Headline">Logged in as {{ userAccount?.name }}</p>
      <button v-on:click="logoutUser" class="w-full text-left" v-bind:class="`pt-1 h-full flex text-lg`" style="font-family: Maersk-Headline">Logout</button>
    </div>
  </nav>
</template>

<script lang="ts">
import Vue, { onMounted, ref } from 'vue';
import { uamAccess, csHub, scmHub } from '@/AppConfig';
import MsalService from '@/service/MsalService';
import { Account } from 'msal';
import ROUTER_PATH from '@/router/router-path';
import NavigationUtils from '@utils/Navigation';

export default {
  setup(props, context) {
    const userAccount = ref<Account>(undefined);

    onMounted(async () => {
      userAccount.value = MsalService.getUserProfile();

      var acc = document.getElementsByClassName('accordion');
      var i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {
          this.classList.toggle('active');

          var panel = this.nextElementSibling;
          if (panel.style.display === 'block') {
            panel.style.display = 'none';
          } else {
            panel.style.display = 'block';
            if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
            } else {
              panel.style.maxHeight = panel.scrollHeight + 'px';
            }
          }
        });
      }
    });

    return {
      ROUTER_PATH,
      MsalService,
      userAccount,
      csHub,
      scmHub,
      uamAccess,
      NavigationUtils,
    };
  },
  methods: {
    navigateToHomePage() {
      this.$router.push(ROUTER_PATH.Home.path);
    },
    navigateToDeveloperHub() {
      this.$router.push(ROUTER_PATH.DeveloperHubHome.path);
    },
    navigateToDashboard() {
      this.$router.push(ROUTER_PATH.Dashboard.path);
    },
    navigateToDeveloperHubHome() {
      this.$router.push(ROUTER_PATH.DeveloperHubHome.path);
    },
    navigateToPlatformHealth() {
      this.$router.push(ROUTER_PATH.PlatFormHealth.path);
    },
    navigateToTrendsDashboard() {
      this.$router.push(ROUTER_PATH.TrendsDashboard.path);
    },
    navigateToMiscAnalyticsDashboard() {
      this.$router.push(ROUTER_PATH.MiscAnalyticsDashboard.path);
    },
    navigateToUserAnalyticsDashboard() {
      this.$router.push(ROUTER_PATH.UserAnalyticsDashboard.path);
    },
    logoutUser() {
      MsalService.initiateLogout();
    },
  },
};
</script>