<template>
  <div>
    <SiteHeader :pageTitle="`Dashboards`" />
    <div class="md:flex content" data-dev-hint="container">
      <input type="checkbox" id="menu-open" class="hidden" />
      <Sidebar class="md:hidden" />
      <main class="overflow-hidden flex-1">
        <SupersetDashboard class="cus" :dashboardId="engineeringMetricsDashboardId" :containTabs="true" />
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import SupersetDashboard from '@features/superset-dashboard/SupersetDashboard.vue';
import { ref } from '@vue/reactivity';
import { engineeringMetricsDashboardId, engineeringMetricsWorkstreamDashboardId } from '@/AppConfig';
import SiteHeader from '@components/site-header/SiteHeader.vue';
import Sidebar from '@features/sidebar/Sidebar.vue';

export default {
  components: {
    SupersetDashboard,
    SiteHeader,
    Sidebar,
  },
  setup() {
    const tabsTypes = {
      byAggregated: '1',
      byWorkstreams: '2',
    };

    const currentTab = ref(tabsTypes.byAggregated);

    const viewByAggregated = () => {
      console.log('clicked1');
      currentTab.value = tabsTypes.byAggregated;
    };

    const viewByWorkstreams = () => {
      console.log('clicked2');
      currentTab.value = tabsTypes.byWorkstreams;
    };

    return {
      engineeringMetricsDashboardId,
      tabsTypes,
      currentTab,
      viewByAggregated,
      viewByWorkstreams,
      engineeringMetricsWorkstreamDashboardId,
    };
  },
  mounted() {
    document.title = `SCP | Dashboard | Engineering Metrics`;
  },
};
</script>
