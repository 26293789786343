import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_BASE_URL, applicationId } from '@/AppConfig';
import MsalService from './MsalService';

/**
 * Wrapper function to make api calls through axios.
 * @param options AxiosRequestConfig
 * @returns Promise
 */
class HttpService {
    private client: AxiosInstance

    /**
     * Callback function for success
     * @param response API response
     * @returns API data
     */
    private onSuccess(response: any) {
        return response.data;
    }

    /**
     *
     * @param error API error
     * @returns Rejection promise
     */
    private onError(error: any) {
        return Promise.reject(error.response || error.message);
    }

    async makeRequest(options: AxiosRequestConfig) {
        const token = await MsalService.getAccessToken();
        return axios({
            baseURL: API_BASE_URL,
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Application-Key': applicationId,
                Authorization: `Bearer ${token}`,
                'Api-Version': 'v2',
                ...options.headers
            },
            params: options.params,
            method: options.method,
            url: options.url,
            data: options.data
        })
            .then(this.onSuccess)
            .catch(this.onError);
    }
}

export default HttpService;
