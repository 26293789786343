import { createRouter, createWebHistory } from 'vue-router';
import jwtDecode from 'jwt-decode';
import MsalService from '../service/MsalService';
import ROUTER_PATH from './router-path';
import PathUtils from "@utils/PathUtils";
import PensieveInstance from "@/service/PensieveService";

/**
 * Defines all the routes of the application.
 */
const routes = [
    { ...ROUTER_PATH.Home },
    { ...ROUTER_PATH.Dashboardsv1 },
    { ...ROUTER_PATH.Dashboard },
    { ...ROUTER_PATH.EngineeringMetricsDashboard },
    { ...ROUTER_PATH.DeveloperHubHome },
    { ...ROUTER_PATH.PlatFormHealth },
    { ...ROUTER_PATH.TrendsDashboard },
    { ...ROUTER_PATH.UserAnalyticsDashboard },
    { ...ROUTER_PATH.MiscAnalyticsDashboard },
    { ...ROUTER_PATH.NotFound },
    { ...ROUTER_PATH.EngineeringMetricsDashboardv1 },
    { ...ROUTER_PATH.GlobalSearchDashboard },
    { ...ROUTER_PATH.EventStatistics },
    { ...ROUTER_PATH.Assets },
    { ...ROUTER_PATH.MPRStatistics }
    // { ...ROUTER_PATH.DeveloperHubTools }
];
/**
 * Router object
 */
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    console.log(MsalService.isUserLoggedIn())
    if (MsalService.isUserLoggedIn()) {
      const token = await MsalService.getAccessToken();

      const userOid = jwtDecode(token).oid;
      PensieveInstance.setUser(userOid);

      next();
    } else MsalService.initiateLogin();
});

router.afterEach((to, from, failure) => {
    if (!failure) {
      PensieveInstance.firePageView(to.fullPath);
    }
});

export default router;