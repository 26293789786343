/* eslint-disable no-unused-vars */
import HttpRequest from './HttpService';
import { } from '@/extensions/String';
import { ServiceSpecData } from '@/features/developer-hub/service-documentation/ServiceDocumentationTypes';
import { CapabilityListData } from '@/features/developer-hub/sidebar/DeveloperHubSidebarTypes';
import { IndexItem, SearchAllResponseData, SearchResponseData } from '@/features/platform-search/PlatformSearchTypes';
import { realtimeAnalyticsApplicationKey } from '@/AppConfig';
import { DataExplorerResult } from '@/features/data-explorer/DataExplorerTypes';
import { PlatformSearchIndexes } from '@/features/platform-search/PlatformSearchService';
import { AuthTokenData } from '@/features/superset-dashboard/SupersetDashboardType';
import { Buffer } from 'buffer';
// eslint-disable-next-line no-shadow
const enum API_ENDPOINTS {
    GET_CAPABILITIES = '/developer-hub/capabilities',
    GET_CAPABILITY_SERVICES = '/developer-hub/capabilities/:capabilityId',
    GET_SERVICE_SPEC = '/developer-hub/capabilities/:capabilityId/services/:serviceId',
    GET_DASHBOARD_VIEWER_TOKEN = '/developer-hub/guest/token',
    PLATFORM_SEARCH_QUERY_ANY = '/platform/search/:index',
    PLATFORM_SEARCH_QUERY_ALL = '/platform/search/all',
    DATA_EXPLORER_QUERY = '/realtime-analytics/data/query',
    PLATFORM_SEARCH_AUTOCOMPLETE = '/platform/search/autocomplete'
}

class SGService {
    private static instance: SGService;

    public static get Instance() {
        if (this.instance) return this.instance;
        return new this();
    }

    async getCapabilityListData(): Promise<CapabilityListData[]> {
        const response = new HttpRequest().makeRequest({
            url: API_ENDPOINTS.GET_CAPABILITIES,
            method: 'GET',
        });
        return response;
    }

    async getServiceSpecData(capabilityId: string, serviceId: string): Promise<ServiceSpecData> {
        const url = API_ENDPOINTS.GET_SERVICE_SPEC.format({
            capabilityId,
            serviceId,
        });
        const data: ServiceSpecData = await new HttpRequest().makeRequest({
            url,
            method: 'GET',
        });
        return data;
    }

    async getDashboardViewerToken(dashboardId: String): Promise<AuthTokenData> {
        const url = API_ENDPOINTS.GET_DASHBOARD_VIEWER_TOKEN
        const body = {
            "dashboardId": dashboardId
        }
        const data: AuthTokenData = await new HttpRequest().makeRequest({
            url,
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json"
            }
        });
        localStorage.setItem('dashboard.token', data.token)
        return data;
    }

    async platformSearchQueryAll(indexs: IndexItem[], query: string): Promise<SearchAllResponseData> {
        const url = API_ENDPOINTS.PLATFORM_SEARCH_QUERY_ALL
        let body = {
            "query": query
        }
        // for (const index of indexs) {
        //     body += JSON.stringify({ "index": index.value }) + '\n'
        //     body += JSON.stringify({ "query": { "query_string": { "query": query } }, "from": 0, "size": 20 }) + '\n'
        // }
        const data: SearchAllResponseData = await new HttpRequest().makeRequest({
            url,
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Api-Version": "2.0",
                "Application-Key": realtimeAnalyticsApplicationKey,
            }
        });
        return data;
    }

    async platformSearchQueryAny(index: string, query: string): Promise<SearchResponseData> {
        const mappedIndex = () => {
            switch (index) {
                case "customer_orders_r": return "customer-orders"
                case "shipper_bookings_r": return "shipper-bookings"
                case "receivings_r": return "receivings"
                case "cargo_stuffings_r": return "cargo-stuffings"
                case "shipping_instructions_r": return "shipping-instructions"
                case "carrier_bookings_r": return "carrier-bookings"
                default: return index
            }
        }
        const url = API_ENDPOINTS.PLATFORM_SEARCH_QUERY_ANY.format({
            index: mappedIndex()
        });
        const body = {
            "query": query
        }
        const data: SearchResponseData = await new HttpRequest().makeRequest({
            url,
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Api-Version": "2.0",
                "Application-Key": realtimeAnalyticsApplicationKey,
            }

        });
        return data;
    }

    async platformSearchAutocomplete(query: string): Promise<SearchResponseData> {
        const url = API_ENDPOINTS.PLATFORM_SEARCH_AUTOCOMPLETE
        const body = {
            "query": query
        }
        const data: SearchResponseData = await new HttpRequest().makeRequest({
            url,
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Api-Version": "2.0",
                "Application-Key": realtimeAnalyticsApplicationKey,
            },
            params: {
                raw: 1
            }

        });
        return data;
    }

    async dataExplorerQuery(query: string): Promise<DataExplorerResult[]> {
        const url = API_ENDPOINTS.DATA_EXPLORER_QUERY
        const body = {
            "query": query,
            "context": {
                "sqlOuterLimit": 100
            },
            "resultFormat": "array",
            "header": true
        }
        const data: DataExplorerResult[] = await new HttpRequest().makeRequest({
            url,
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                "Api-Version": "v2",
                "Application-Key": realtimeAnalyticsApplicationKey,
                "X-Forwarded-For": "realtime-analytics"
            }
        });
        return data;
    }
}

export default SGService;
