<template>
  <div class="p-6">
    <div class="relative">
      <h2 class="block my-auto text-00243D text-2xl" style="font-family: Maersk-Regular">Data Explorer</h2>
      <div class="mt-4 w-full h-px bg-B2B2B2"></div>
      <span class="mt-4 text-sm block">Enter query</span>
      <textarea class="mt-2 w-full" rows="5" v-model="queryText" spellcheck="false"></textarea>
      <span v-on:click="onLoadExampleQuery" class="w-full text-right block cursor-pointer text-0073AB">Load example query</span>
      <button class="mt-2 bg-00243D text-white py-2 px-3 rounded-md" v-on:click="onSearch">Run</button>
    </div>

    <div class="mt-4 w-full h-px bg-B2B2B2"></div>

    <!-- Loading -->
    <Loader class="ml-10" :loading="service.loading.value" />

    <!-- Result -->
    <div class="flex flex-col" v-if="!service.loading.value && service.queryData.value?.length > 0">
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full">
          <div class="overflow-hidden">
            <div class="w-full flex cursor-pointer py-2" @click="toggleExpansion">
              <span class="text-00243D text-lg select-none" style="font-family: Maersk-Bold"> Result </span>
            </div>
            <table class="w-full" v-if="service.queryData.value?.length > 0">
              <thead class="bg-white border-b">
                <tr>
                  <th v-for="row in service.queryData.value[0]" v-bind:key="row" scope="col" class="text-sm font-medium text-gray-900 font-bold px-6 py-4 text-left">{{ row }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="[index % 2 == 0 ? 'bg-white' : 'bg-gray-100', 'border-b cursor-pointer']"
                  v-for="(rows, index) in service.queryData.value?.filter((data, rowIndex) => rowIndex > 0)"
                  v-bind:key="index">
                  <td v-for="row in rows" v-bind:key="row" :class="['px-6 py-4 whitespace-nowrap text-sm font-medium']">
                    {{ row || 'null' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="mt-8 w-full h-px bg-B2B2B2"></div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Query error  -->
    <QueryError class="mt-2" :errorMessage="service?.error?.value?.data?.errorMessage" :error="service?.error?.value?.data?.error" :errorClass="service?.error?.value?.data?.errorClass" />
  </div>
</template>

<script lang="ts">
import Vue, { ref } from 'vue';
import DataExplorerService from './DataExplorerService';
import QueryError from './query-error/QueryError.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  components: {
    QueryError,
    Loader,
  },
  setup() {
    const service = new DataExplorerService();
    const queryText = ref<string>();

    const onSearch = () => {
      service.queryDataExplorer(queryText.value);
    };

    const onLoadExampleQuery = () => {
      queryText.value = service.exampleQuery;
      service.loadExampleQuery();
    };

    return {
      queryText,
      onSearch,
      service,
      onLoadExampleQuery,
    };
  },
};
</script>