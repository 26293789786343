<template>
  <aside
    id="sidebar"
    class="
      shadow shadow-lg
      bg-white
      md:w-72
      w-full
      space-y-6
      p-4
      left-0
      transform
      md:translate-x-0
      transition
      duration-200
      ease-in-out
      md:flex md:flex-col md:justify-between
      overflow-y-scroll
      flex-shrink-0
      fixed
      inset-0
      z-50
    "
    data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation">
    <div class="flex flex-col space-y-6" data-dev-hint="optional div for having an extra footer navigation">
      <div class="flex flex-row-reverse items-center md:hidden">
        <label for="menu-open" class="m-2 p-2 focus:outline-none cursor-pointer rounded-md md:hidden bg-white">
          <svg id="menu-open-icon" class="h-6 w-6 transition duration-200 ease-in-out" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <svg id="menu-close-icon" class="h-6 w-6 transition duration-200 ease-in-out" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </label>
      </div>
      <SidebarCommon />
    </div>
  </aside>
</template>

<script lang="ts">
import Vue from 'vue';
import SidebarCommon from '@features/sidebar/SidebarCommon.vue';
export default {
  components: {
    SidebarCommon,
  },
};
</script>