import SGService from "@/service/SGService";
import { ref } from "vue"
import { Hits, IndexItem, SearchAllResponseData, SearchResponseData } from "./PlatformSearchTypes";

export enum PlatformSearchIndexes {
    ALL = "_all",
    CUSTOMER_ORDERS = "customer-orders",
    SHIPPER_BOOKINGS = "shipper-bookings",
    CARRIER_BOOKING = "carrier-bookings",
    SHIPPING_INSTRUCTIONS = "shipping-instructions",
    CARGO_STUFFINGS = "cargo-stuffings",
    RECEIVINGS = "receivings",
    VERIFIED_GROSS_MASS = "verified_gross_mass_filings",
    DOCUMENTS = "documents",
    FORWARDERS_CARGO_RECEIPT = "forwarders_cargo_receipt",
    CARRIER_EVENTS = "carrier_events",
    CASES = "cases",
    CONTAINERS = "containers"
}

class PlatformSearchService {

    loading = ref(false)

    searchData = ref<SearchResponseData>(undefined)

    searchAllData = ref<SearchAllResponseData>(undefined)

    error = ref<string>(undefined)

    customerOrders = ref<Hits[]>([])

    shipperBookings = ref<Hits[]>([])

    carrierBooking = ref<Hits[]>([])

    shippingInstructions = ref<Hits[]>([])

    cargoStuffings = ref<Hits[]>([])

    receivings = ref<Hits[]>([])

    verifiedGrossMass = ref<Hits[]>([])

    documents = ref<Hits[]>([])

    forwardersCargoReceipt = ref<Hits[]>([])

    carrierEvents = ref<Hits[]>([])

    cases = ref<Hits[]>([])

    containers = ref<Hits[]>([])

    modalContent = ref<String>(undefined)

    defaultExpanded = ref<String>('')

    searchOverview = ref<String>(undefined)

    suggestions = ref<SearchResponseData>(undefined)

    suggestionQuery = ref<String>(undefined)

    showSuggestions = ref<boolean>(false)

    items: IndexItem[] = [
        {
            id: '1',
            text: 'All',
            value: PlatformSearchIndexes.ALL,
        },
        {
            id: '2',
            text: 'Customer Orders',
            value: PlatformSearchIndexes.CUSTOMER_ORDERS,
        },
        {
            id: '3',
            text: 'Shipper Bookings',
            value: PlatformSearchIndexes.SHIPPER_BOOKINGS,
        },
        {
            id: '4',
            text: 'Cargo Stuffings',
            value: PlatformSearchIndexes.CARGO_STUFFINGS,
        },
        {
            id: '5',
            text: 'Receivings',
            value: PlatformSearchIndexes.RECEIVINGS,
        },
        {
            id: '6',
            text: 'Carrier Booking',
            value: PlatformSearchIndexes.CARRIER_BOOKING,
        },
        {
            id: '7',
            text: 'Shipping Instructions',
            value: PlatformSearchIndexes.SHIPPING_INSTRUCTIONS,
        },
        {
            id: '8',
            text: 'Verified Gross Mass',
            value: PlatformSearchIndexes.VERIFIED_GROSS_MASS,
        },
        {
            id: '9',
            text: 'Documents',
            value: PlatformSearchIndexes.DOCUMENTS,
        },
        {
            id: '10',
            text: 'Forwarders Cargo Receipt',
            value: PlatformSearchIndexes.FORWARDERS_CARGO_RECEIPT,
        },
        {
            id: '11',
            text: 'Carrier Events',
            value: PlatformSearchIndexes.CARRIER_EVENTS,
        },
        {
            id: '12',
            text: 'Cases',
            value: PlatformSearchIndexes.CASES,
        },
        {
            id: '13',
            text: 'Containers',
            value: PlatformSearchIndexes.CONTAINERS,
        }
    ];

    async queryAny(index: string, query: string) {
        this.suggestions.value = undefined
        this.showSuggestions.value = false
        this.loading.value = true;
        this.reset()
        try {
            // eslint-disable-next-line max-len
            this.searchData.value = await (await SGService.Instance.platformSearchQueryAny(index, query));
            this.mapHits()
        } catch (error) {
            console.log(error)
            this.error.value = `Error in fetching capability list. ${error}`;
        }
        this.loading.value = false;
    }

    async queryAll(index: string, query: string) {
        this.suggestions.value = undefined
        this.showSuggestions.value = false
        this.loading.value = true;
        this.reset()
        try {
            // eslint-disable-next-line max-len
            this.searchAllData.value = await (await SGService.Instance.platformSearchQueryAll(this.items.filter(eachItem => eachItem.value != PlatformSearchIndexes.ALL), query));
            this.mapAllHits()
        } catch (error) {
            console.log(error)
            this.error.value = `Error in fetching capability list. ${error}`;
        }
        this.loading.value = false;
    }

    async autocomplete(query: string) {
        this.suggestionQuery.value = query
        this.showSuggestions.value = true
        try {
            // eslint-disable-next-line max-len
            const data = await (await SGService.Instance.platformSearchAutocomplete(query));
            this.suggestions.value = undefined
            // if (data?.hits?.hits.find(allHits => allHits._id?.toLocaleLowerCase().startsWith(this.suggestionQuery.value?.toLocaleLowerCase()))) {
            this.suggestions.value = data
            // }
        } catch (error) {
            console.log(error)
            this.error.value = `Error in fetching autocomplete. ${error}`;
        }
    }

    mapAllHits() {
        this.searchData.value = this.searchAllData.value
        this.mapHits()
    }

    mapHits() {
        this.customerOrders.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.CUSTOMER_ORDERS)
        this.shipperBookings.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.SHIPPER_BOOKINGS)
        this.carrierBooking.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.CARRIER_BOOKING)
        this.shippingInstructions.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.SHIPPING_INSTRUCTIONS)
        this.cargoStuffings.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.CARGO_STUFFINGS)
        this.receivings.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.RECEIVINGS)
        this.verifiedGrossMass.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.VERIFIED_GROSS_MASS)
        this.documents.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.DOCUMENTS)
        this.forwardersCargoReceipt.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.FORWARDERS_CARGO_RECEIPT)
        this.carrierEvents.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.CARRIER_EVENTS)
        this.cases.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.CASES)
        this.containers.value = this.searchData.value.Items?.filter((eachHit) => eachHit.EntityType == PlatformSearchIndexes.CONTAINERS)

        this.searchOverview.value = `Showing ${this.searchData.value?.Items.length} results`

        for (let i = 0; i < this.items.length; i++) {
            if (this.searchData.value?.Items.find(datum => datum.EntityType == this.items[i].value)) {
                this.defaultExpanded.value = this.items[i].value
                break;
            }

        }
    }

    reset() {
        this.searchOverview.value = undefined
        this.searchData.value = undefined
        this.customerOrders.value = []
        this.shipperBookings.value = []
        this.carrierBooking.value = []
        this.shippingInstructions.value = []
        this.cargoStuffings.value = []
        this.receivings.value = []
        this.verifiedGrossMass.value = []
        this.documents.value = []
        this.forwardersCargoReceipt.value = []
        this.carrierEvents.value = []
        this.cases.value = []
        this.containers.value = []
    }

    setModalContent(value: String) {
        this.modalContent.value = value
    }
}

export default PlatformSearchService