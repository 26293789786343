<template>
  <div>
    <SiteHeader :pageTitle="`Dashboards`" />
    <SupersetDashboard :dashboardId="userAnalyticsDashboardId" />
  </div>
</template>

<script lang="ts">
import SupersetDashboard from '@features/superset-dashboard/SupersetDashboard.vue';
import { userAnalyticsDashboardId } from '@/AppConfig';
import SiteHeader from '@components/site-header/SiteHeader.vue';
import SiteFooter from '@components/site-footer/SiteFooter.vue';
export default {
  components: {
    SupersetDashboard,
    SiteHeader,
    SiteFooter,
  },
  setup() {
    return {
      userAnalyticsDashboardId,
    };
  },
  mounted() {
    document.title = `SCP | Dashboard | User Analytics`;
  },
};
</script>
