<template>
  <div class="px-6 md:px-12 lg:px-24 py-12">
    <div class="max-w-7xl mx-auto">
      <label class="text-4xl text-00243D" style="font-family: Maersk-Headline">Developers HUB</label>
      <p class="mt-5 text-545454">One-stop developer experience hub to manage SCP APIs, develop apps and services using self-guided how-to articles, technical articles, and tutorials.</p>
      <button class="mt-6 py-2 px-4 mr-2 rounded-md bg-42B0D5 text-white" v-on:click="navigateToDevhub">Get Started</button>
      <div class="mt-14 md:flex">
        <div class="bg-E9F6FC text-center md:w-1/2 md:mr-2 py-14 px-7 rounded-md cursor-pointer" v-on:click="NavigationUtils.navigateExternal(maerskDesignSystem)">
          <p class="text-2xl" style="font-family: Maersk-Headline">Maersk Design System</p>
          <img src="@assets/mds-1.svg" alt="" class="mt-5 mx-auto mb-4" />
          <p class="text-545454">
            Serving both design and development communities across the organisation, the Maersk Design System provides foundations, components and guidelines for designing and developing user
            interfaces.
          </p>
        </div>
        <div class="bg-00243D mt-4 md:mt-0 text-center text-white md:w-1/2 md:ml-2 py-14 px-7 rounded-md cursor-pointer" v-on:click="NavigationUtils.navigateExternal(uiComponents)">
          <p class="text-2xl" style="font-family: Maersk-Headline">MDS Community Library (SCM)</p>
          <img src="@assets/ui-1.svg" alt="" class="mt-5 mx-auto mb-4" />
          <p>Core functionality library of UI components for SCM Platform. The aim is to provide a common, consistent and proficient solution for all the SCM apps across the platform.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { maerskDesignSystem, uiComponents } from '@/AppConfig';
import ROUTER_PATH from '@/router/router-path';
import NavigationUtils from '@utils/Navigation';

export default {
  data() {
      return {
        maerskDesignSystem,
        uiComponents,
        NavigationUtils,
      }
  },
  methods: {
    navigateToDevhub() {
      this.$router.push(ROUTER_PATH.DeveloperHubHome.path);
    },
  },
};
</script>