<template>
  <div class="px-6 md:px-12 lg:px-24 py-12 bg-F7F7F7 text-center">
    <div class="max-w-7xl mx-auto">
      <label class="text-2xl text-00243D" style="font-family: Maersk-Headline">Our HUBS</label>
      <!-- <p class="mt-5 text-545454">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ultrices lobortis augue et bibendum. Suspendisse non diam id libero congue suscipit. Aliquam turpis arcu, suscipit et egestas eu,
      sagittis et libero. Donec sit amet convallis elit, ut lacinia ipsum. Proin dignissim ipsum sed eros hendrerit cursus. Donec consectetur justo vitae leo semper rhoncus. Vivamus tincidunt laoreet
      orci vitae consectetur.
    </p> -->
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        <div class="w-full bg-white text-center px-6 py-10 border rounded-md cursor-pointer hover:shadow-md" v-on:click="NavigationUtils.navigateExternal(csHub)">
          <img class="mx-auto" src="@assets/customer-5.png" style="height: 70px" />
          <h1 class="my-4 text-42B0D5 text-2xl cursor-pointer">Customer Service HUB</h1>
          <p class="text-545454">
            Unified self-service portal for Maersk customer service agents to serve the SCM customers. The hub provides visibility about the customer’s supply chain, manage exceptions and execute
            workflow steps.
          </p>
        </div>
        <div class="w-full bg-white text-center px-6 py-10 border rounded-md cursor-pointer hover:shadow-md" v-on:click="NavigationUtils.navigateExternal(scmHub)">
          <img class="mx-auto" src="@assets/customer-1.png" style="height: 70px" />
          <h1 class="my-4 text-42B0D5 text-2xl cursor-pointer">SCM HUB</h1>
          <p class="text-545454">
            Self-service digital experience hub for SCM consignees and shippers to manage their supply chain. SCM Hub provides shipment management, visibility, reporting, analytics and exceptions
            management capabilities.
          </p>
        </div>
        <div class="w-full bg-white text-center px-6 py-10 border rounded-md cursor-pointer hover:shadow-md" v-on:click="NavigationUtils.navigateExternal(adminHub)">
          <img class="mx-auto" src="@assets/admin.png" style="height: 70px" />
          <h1 class="my-5 text-42B0D5 text-2xl cursor-pointer">Admin HUB</h1>
          <p class="text-545454">
            Simple to use administration hub for SCM Platform to manage user privileges, customer on-boarding and settings. ADMIN Hub provides centralised customer configuration, feature management
            and user access management capabilities - only accessible to SCM system administrators and platform support groups.
          </p>
        </div>
        <div class="w-full bg-white text-center px-6 py-10 border rounded-md cursor-pointer hover:shadow-md" v-on:click="navigateToDevhub">
          <img class="mx-auto" src="@assets/workspace.png" style="height: 70px" />
          <h1 class="my-5 text-42B0D5 text-2xl cursor-pointer">Developer HUB</h1>
          <p class="text-545454">One-stop developer experience hub to manage SCP APIs, develop apps and services using self-guided how-to articles, technical articles, and tutorials.</p>
        </div>
        <!-- <div class="w-full bg-white text-center px-6 py-10 border rounded-md cursor-pointer hover:shadow-md" v-on:click="NavigationUtils.navigateExternal(knowMoreLink)">
          <img class="mx-auto" src="@assets/more-1.png" style="height: 70px" />
          <h1 class="my-5 text-42B0D5 text-2xl cursor-pointer">Know More on SCP</h1>
          <p class="text-545454">More information, videos, roadmaps, FAQS, etc. on New SCM platform and its product offerings.</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ROUTER_PATH from '@/router/router-path';
import { csHub, scmHub, adminHub, knowMoreLink } from '@/AppConfig';
import NavigationUtils from '@utils/Navigation';

export default {
  setup() {
    return {
      csHub,
      scmHub,
      adminHub,
      knowMoreLink,
      NavigationUtils,
    };
  },
  methods: {
    navigateToDevhub() {
      this.$router.push(ROUTER_PATH.DeveloperHubHome.path);
    },
  },
};
</script>