<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden pt-20">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay @click="onClose" class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 pt-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-500"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-500"
            leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div class="pointer-events-auto relative w-screen max-w-3xl">
              <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div class="flex justify-between px-4 sm:px-6">
                  <DialogTitle class="text-lg font-medium text-gray-900"> {{ dataCategory }} </DialogTitle>
                  <button
                    type="button"
                    class="
                      mt-3
                      w-full
                      inline-flex
                      justify-center
                      rounded-md
                      border border-gray-300
                      shadow-sm
                      px-2
                      py-2
                      bg-white
                      text-base
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      outline-none
                      sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                    "
                    @click="onClose"
                    ref="cancelButtonRef">
                    <img src="@assets/close-1.svg" class="w-4" alt="" srcset="" />
                  </button>
                </div>
                <div class="relative mt-6 flex-1 px-4 sm:px-6">
                  <vue-json-pretty class="bg-gray-50 mt-2" :path="'res'" :data="data" />
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, watch } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationIcon } from '@heroicons/vue/outline';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    VueJsonPretty,
  },
  props: {
    data: {
      type: Object,
    },
    modelValue: { type: Boolean },
    dataCategory: {
      type: String,
    },
  },
  setup(props, context) {
    const onClose = () => {
      context.emit('update:modelValue', false);
    };

    return {
      onClose,
    };
  },
};
</script>