<template>
  <div>
    <SiteHeader :pageTitle="`Developer HUB`" />
    <div class="md:flex content" data-dev-hint="container">
      <input type="checkbox" id="menu-open" class="hidden" />
      <DeveloperHubSidebar class="" @onServiceSelected="onServiceSelected" @onMenuSelected="onMenuSelected" />
      <main class="overflow-auto flex-1" id="content">
        <div class="mx-auto justify-center">
          <DeveloperHubWelcome class="m-10 max-w-7xl" v-bind:hidden="currentWindow != Menu.Home" />
          <ServiceDocumentation class="m-10 max-w-7xl" v-bind:hidden="currentWindow != Menu.Api" :capabilityId="selectedCapability" :serviceId="selectedService" />
          <iframe v-if="currentWindow == Menu.NSCPTracer" v-bind:src="tracer" style="width: 100%; height: calc(100vh - 65px)" frameborder="0"></iframe>
          <PowerBIReport style="width: 100%; height: calc(100vh - 65px)" v-if="currentWindow == Menu.DataQuality" :reportId="dataQualityReportId" :groupId="dataQualityGroupId" />
          <PlatformSearch v-if="currentWindow == Menu.PlatformSearch" />
          <DataExplorer v-if="currentWindow == Menu.DataExplorer" />
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { ref } from 'vue';
import SiteHeader from '@components/site-header/SiteHeader.vue';
import DeveloperHubSidebar from '@/features/developer-hub/sidebar/DeveloperHubSidebar.vue';
import DeveloperHubWelcome from '@/features/developer-hub/welcome/DeveloperHubWelcome.vue';
import ServiceDocumentation from '@features/developer-hub/service-documentation/ServiceDocumentation.vue';
import { Menu } from '@/features/developer-hub/sidebar/DeveloperHubSidebarTypes';
import { tracer, dataQualityReportId, dataQualityGroupId } from '@/AppConfig';
import PowerBIReport from '@/features/power-bi/report/PowerBIReport.vue';
import PlatformSearch from '@features/platform-search/PlatformSearch.vue';
import DataExplorer from '@features/data-explorer/DataExplorer.vue';

export default {
  components: {
    SiteHeader,
    DeveloperHubSidebar,
    DeveloperHubWelcome,
    ServiceDocumentation,
    PowerBIReport,
    PlatformSearch,
    DataExplorer,
  },
  setup() {
    const selectedCapability = ref<string>('');
    const selectedService = ref<string>('');

    const currentWindow = ref<Menu>(Menu.Home);

    const onServiceSelected = ({ capabilityId, serviceId }) => {
      currentWindow.value = Menu.Api;
      selectedService.value = serviceId;
      selectedCapability.value = capabilityId;
    };

    const onMenuSelected = (menu: Number) => {
      switch (menu) {
        case Menu.NSCPTracer:
          currentWindow.value = Menu.NSCPTracer;
          break;
        case Menu.Home:
          currentWindow.value = Menu.Home;
          break;
        case Menu.DataQuality:
          currentWindow.value = Menu.DataQuality;
          break;
        case Menu.PlatformSearch:
          currentWindow.value = Menu.PlatformSearch;
          break;
        case Menu.DataExplorer:
          currentWindow.value = Menu.DataExplorer;
          break;
      }
    };

    return {
      onServiceSelected,
      selectedCapability,
      selectedService,
      onMenuSelected,
      Menu,
      currentWindow,
      tracer,
      dataQualityReportId,
      dataQualityGroupId,
    };
  },
  mounted() {
    document.title = `SCP | Developer Hub`;
  },
};
</script>

<style scoped>
.content {
  height: calc(100vh - 65px);
}
</style>
