import SGService from "@/service/SGService"
import { ref } from "@vue/runtime-core"
import { ServiceSpecData } from "./ServiceDocumentationTypes"

class ServiceDocumentationService {
    serviceSpec = ref<ServiceSpecData>(undefined)

    serviceSpecLoading = ref(false)

    error = ref<string>(undefined)

    async getServiceSpec(capabilityId: string, serviceId: string) {
        this.error.value = undefined;
        this.serviceSpec.value = undefined;
        this.serviceSpecLoading.value = true;
        try {
            this.serviceSpec.value = await SGService.Instance.getServiceSpecData(capabilityId, serviceId);
            if (this.serviceSpec.value?.status === 500) {
                this.error.value = 'Error in swagger specification file. Please reach out to respective capability team.';
            } else {
                this.mapServer();
            }
        } catch (error) {
            console.log(error)
            this.error.value = `Failed to load swagger specification for '${capabilityId}' with service id '${serviceId}'`;
        }
        this.serviceSpecLoading.value = false;
    }

    private mapServer() {
        switch (window.location.hostname) {
            case 'scm.maersk.com':
                this.serviceSpec.value.swaggerSpec.servers = [
                    {
                        url: 'https://api.scm.maersk.com',
                        description: 'Production Environment',
                    },
                ];
                break;
            default:
                this.serviceSpec.value.swaggerSpec.servers = [
                    {
                        url: 'https://api-preprod.scm.maersk.com',
                        description: 'Preprod Environment',
                    },
                    {
                        url: 'https://api-test.scm.maersk.com',
                        description: 'Test Environment',
                    },
                    {
                        url: 'https://api-dev.scm.maersk.com',
                        description: 'Development Environment',
                    },
                ];
        }
    }
}

export default ServiceDocumentationService