<template>
  <div class="p-10">
    <label class="text-4xl text-00243D block" style="font-family: Maersk-Headline-Light"> Page not found :( </label>
    <button class="bg-00243D text-white py-2 px-4 rounded-md mt-8" v-on:click="naviagateToHome">Back to home</button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ROUTER_PATH from '@/router/router-path';
export default {
  methods: {
    naviagateToHome() {
      this.$router.push(ROUTER_PATH.Home.path);
    },
  },
  mounted() {
    document.title = `SCP | Not Found`;
  },
};
</script>