<template>
  <div>
    <div class="flex flex-col">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <div class="w-full flex cursor-pointer py-2" @click="toggleExpansion">
              <img class="h-2 my-auto" src="@assets/icons/dropdown-expand.svg" v-if="!expanded" />
              <img class="h-2 my-auto" src="@assets/icons/dropdown-collapse.svg" v-if="expanded" />
              <span class="ml-3 text-00243D text-lg select-none" style="font-family: Maersk-Bold">
                {{ dataCategory }} <span class="text-sm" style="font-family: Maersk-Regular">({{ data.length }} item{{ Number(data.length) > 1 ? 's' : '' }})</span>
              </span>
            </div>
            <table class="min-w-full" v-if="expanded">
              <thead class="bg-white border-b">
                <tr>
                  <th v-for="column in tableColumns" v-bind:key="column.columnName" scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">{{ column.displayName }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-on:click="showModal(index)" :class="[index % 2 == 0 ? 'bg-white' : 'bg-gray-100', 'border-b cursor-pointer']" v-for="(order, index) in data" v-bind:key="order.ID">
                  <td
                    v-for="(column, columnIndex) in tableColumns"
                    v-bind:key="column.columnName"
                    :class="['px-6 py-4 whitespace-nowrap text-sm font-medium', columnIndex == 0 ? 'text-0073AB font-bold hover:underline' : '']">
                    {{ column.columnName2 ? order.Data[column.columnName][column.columnName2] : order.Data[column.columnName] }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="mt-8 w-full h-px bg-B2B2B2"></div> -->
          </div>
        </div>
      </div>
    </div>
    <Modal :data="modalContent" v-model="open" :dataCategory="dataCategory" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType, ref } from 'vue';
import { Hits, TableColumns } from '@features/platform-search/PlatformSearchTypes';
import Modal from '@features/platform-search/modal/Modal.vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  props: {
    data: {
      type: Array as PropType<Hits[]>,
    },
    dataCategory: {
      type: String,
    },
    defaultExpanded: {
      type: Boolean,
    },
    tableColumns: {
      type: Array as PropType<TableColumns[]>,
    },
  },
  components: {
    Modal,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup(props) {
    const open = ref(false);

    const modalContent = ref<Object>(undefined);

    const expanded = ref<Boolean>(props.defaultExpanded);

    const toggleExpansion = () => {
      expanded.value = !expanded.value;
    };

    const showModal = (index) => {
      modalContent.value = { ...props.data[index].Data };
      open.value = true;
    };

    return {
      modalContent,
      showModal,
      expanded,
      toggleExpansion,
      open,
    };
  },
};
</script>