
<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 flex items-center">
        <button>
          <img
            src="@assets/icons/search.svg"
            id="currency"
            name="currency"
            class="w-7 focus:ring-B2B2B2 focus:border-B2B2B2 h-full py-0 pl-2 mr-3 border-transparent bg-transparent text-gray-500 rounded-md" />
        </button>
      </div>
      <input
        v-on:keyup.enter="emitSearchEvent"
        v-model="inputValue"
        type="text"
        name="price"
        id="price"
        class="focus:ring-B2B2B2 focus:border-B2B2B2 block w-full pl-10 pr-48 pr-12 border-gray-300 rounded-md"
        placeholder="Search in"
        v-debounce:500="onCompletedTyping" />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <select
          v-model="selectedCategory"
          @change="emitSearchEvent"
          id="currency"
          name="currency"
          class="focus:ring-transparent focus:border-transparent h-full py-0 pl-2 pr-7 cursor-pointer border-transparent bg-transparent rounded-md">
          <option v-bind:value="item.value" class="cursor-pointer px-2 py-2" v-for="item in items" v-bind:key="item.id">{{ item.text }}</option>
        </select>
      </div>
      <div v-if="showSuggestions" class="block absolute top-12 left-0 right-0 ml-2 w-full ml-auto mr-auto bg-white shadow-md">
        <span v-on:click="onSuggestionClicked(hit._id)" v-for="hit in suggestions" v-bind:key="hit._id" class="block py-2 px-3 hover:bg-gray-200 cursor-pointer">{{ hit._id }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, ref, watch } from 'vue';
import { IndexItem } from '../PlatformSearchTypes';
export default {
  props: {
    items: { type: Array as PropType<IndexItem[]> },
    modelValue: { type: String },
    suggestions: { type: Array },
    showSuggestions: { type: Boolean}
  },
  setup(props, context) {
    const selectedCategory = ref<String>(props?.items?.length > 0 ? props.items[0].value : undefined);
    const inputValue = ref<String>('');

    const emitSearchEvent = () => {
      context.emit('search', { queryText: inputValue.value, queryCategory: selectedCategory.value });
    };

    // watch(inputValue, () => {
    //   context.emit('update:modelValue', inputValue.value);
    // });

    const onCompletedTyping = () => {
      context.emit('update:modelValue', inputValue.value);
    };

    const onSuggestionClicked = (value: string) => {
      inputValue.value = value;
      emitSearchEvent();
    };

    return {
      selectedCategory,
      inputValue,
      emitSearchEvent,
      onSuggestionClicked,
      onCompletedTyping,
    };
  },
};
</script>
