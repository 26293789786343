import { ref } from "@vue/runtime-core";
import { WorkStreamsType } from "./PHOperationalMonitorTypes";

class PHOperationalMonitorsService {
    operationalMonitorsWorkstreams = ref<WorkStreamsType[]>([])

    constructor() {
        this.operationalMonitorsWorkstreams.value = [
            {
                name: '1. Platform Overall',
                description: '',
                link: 'https://app.datadoghq.eu/dashboard/2xf-c5z-5xg/nscp-platform-overall-view---prod?from_ts=1628474615225&to_ts=1628478215225&live=true',
                image: 'https://www.callcentrehelper.com/images/stories/2020/04/call-centre-report-magnifying-glass-over-screen-760.png',
            },
            {
                name: '2. Origin - Order Management(OM)',
                description: 'Origin Order Management is Customer Order and Shipper Booking management service. It exposes various endpoints for ingestion of CO and SBK data and also facilitates retrieval of those.',
                link: 'https://app.datadoghq.eu/dashboard/q46-29r-ded?from_ts=1628911741918&to_ts=1629084541918&live=true',
                image: 'https://healthpayerintelligence.com/images/site/article_headers/_normal/GettyImages-1143454455.jpg',
            },
            {
                name: '3. Document Management(Docman)',
                description: 'Document Management (DocMan) provides the ability to securely store, edit and search documents.',
                link: 'https://app.datadoghq.eu/dashboard/359-ifj-pxc/docman-prod-dashboard---v2?from_ts=1628474987288&to_ts=1628478587288&live=true',
                image: 'https://media.sproutsocial.com/uploads/2017/05/Social-Media-Analytics-Reporting-Guide.png',
            },
            {
                name: '4. International Transport Management(ITM)',
                description: '',
                link: 'https://app.datadoghq.eu/dashboard/ytt-xp6-hdp/itm-dashboard-prod',
                image: 'https://assets-global.website-files.com/5a690960b80baa0001e05b0f/5b44b2bd875a69e338db700b_blog_post_hero-status-reports%402x.png',
            },
            {
                name: '5. Event & Milestones(EnM)',
                description: '',
                link: 'https://app.datadoghq.eu/dashboard/hap-m6a-9i8/event-and-milestones---prod?from_ts=1626413194148&to_ts=1626416794148&live=true',
                image: 'https://cdn.searchenginejournal.com/wp-content/uploads/2017/06/seo-reports-760x400.png',
            },
            {
                name: '6. Destination',
                description: 'Destination Services Platform offers the services to manage Import Supply Chains.',
                link: 'https://app.datadoghq.eu/dashboard/g92-zux-5wv/destination-control-panel',
                image: 'https://img-cdn.inc.com/image/upload/w_1920,h_1080,c_fill/images/panoramic/GettyImages-523185476_457386_rbcvns.jpg',
            },
            {
                name: '7. Alert Exception and Communication(AEC)',
                description: '',
                link: 'https://app.datadoghq.eu/dashboard/b64-3ug-4n7/aec-prod-dashboard-cloned?from_ts=1625553568880&to_ts=1626158368880&live=true',
                image: 'https://cdn.searchenginejournal.com/wp-content/uploads/2017/06/seo-reports-760x400.png',
            },
            {
                name: '8. Financial Operations(FinOps)',
                description: 'The Finops solution embeds job costing & closure as an integral part of the E2E shipment process flow.',
                link: 'https://app.datadoghq.eu/dashboard/tdx-eph-e2f/finops-dashboard---prod-cloned?from_ts=1624787411277&to_ts=1627379411277&live=true',
                image: 'https://www.singlegrain.com/wp-content/uploads/2020/01/SG-8-Essential-SEO-Reports-to-Create-for-Your-Client-in-2020.jpg',
            },
            {
                name: '9. Configuration, Migration and Onboarding(CMO)',
                description: '',
                link: 'https://app.datadoghq.eu/dashboard/iqw-49f-tyv/cmo?from_ts=1628474519028&to_ts=1628478119028&live=true',
                image: 'https://img-cdn.inc.com/image/upload/w_1920,h_1080,c_fill/images/panoramic/GettyImages-523185476_457386_rbcvns.jpg',
            },

        ];
    }
}

export default PHOperationalMonitorsService