<template>
  <div>
    <h1 class="mt-14 text-4xl text-00243D" style="font-family: Maersk-Headline-Light">Quality Metrics</h1>
    <!-- <p class="mt-4 text-545454">
      Cras ornare libero magna, sed eleifend nibh dapibus ac. Phasellus vel dolor egestas, dignissim sem non, commodo mauris. Pellentesque quis odio fringilla, faucibus leo ut, elementum lorem. Morbi
      eget est ac mauris faucibus iaculis. Suspendisse eu arcu odio. Nulla rhoncus, lectus non dignissim rhoncus, leo massa tristique lorem, laoreet volutpat felis risus in ipsum. Sed viverra ligula
      nec eros luctus lobortis. Nam iaculis pulvinar purus. Sed malesuada velit feugiat lorem molestie, et volutpat dolor sollicitudin.
    </p> -->

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
      <div
        class="p-4 border rounded-md cursor-pointer hover:shadow-md"
        v-on:click="openPageInNewTab(workstream.link)"
        v-for="workstream in service.qualityMetricsWorkstreams.value"
        v-bind:key="workstream.name">
        <div class="flex">
          <img src="@assets/book-open-1.svg" alt="" />
          <h5 class="ml-5 my-auto text-lg text-141414">{{ workstream.name }}</h5>
        </div>
        <!-- <p class="mt-4 text-545454">{{ workstream.description }}</p> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import PHQualityMetricsService from './PHQualityMetricsService';
import NavigationUtils from '@utils/Navigation';

export default {
  setup() {
    const service = new PHQualityMetricsService();

    return {
      service,
    };
  },
  methods: {
    openPageInNewTab(link: string = '/') {
      if (link.startsWith('/')) {
        this.$router.push(link);
      } else NavigationUtils.navigateExternal(link);
    },
  },
};
</script>