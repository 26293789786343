<template>
  <div class="sm:px-0">
    <label class="text-5xl text-00243D" style="font-family: Maersk-Headline-Light"> Welcome to Developer Hub </label>
    <p class="text-545454 mt-4">One-stop experience hub for developers to manage APIs, shared services, component, libraries using self-guided how-to-articles, technical articles and tutorials.</p>
    <p class="mt-16 text-00243D text-4xl" style="font-family: Maersk-Headline-Light">API Registration Process</p>
    <p class="text-545454 mt-4">Register your APIs in a self-service two-step process. Follow the guide here.</p>
    <button class="bg-00243D text-white py-2 px-4 rounded-md mt-8" v-on:click="NavigationUtils.navigateExternal(apiRegistration)">Learn more</button>

    <div class="mt-14 md:flex">
      <div class="bg-E9F6FC text-center md:w-1/2 md:mr-2 py-14 px-7 rounded-md cursor-pointer" v-on:click="NavigationUtils.navigateExternal(maerskDesignSystem)">
        <p class="text-2xl" style="font-family: Maersk-Headline">Maersk Design System</p>
        <img src="@assets/mds-1.svg" alt="" class="mt-5 mx-auto mb-4" />
        <p class="text-545454">
          Serving both design and development communities across the organisation, the Maersk Design System provides foundations, components and guidelines for designing and developing user
          interfaces.
        </p>
      </div>
      <div class="bg-00243D text-center text-white md:w-1/2 md:ml-2 mt-4 md:mt-0 py-14 px-7 rounded-md cursor-pointer" v-on:click="NavigationUtils.navigateExternal(uiComponents)">
        <p class="text-2xl" style="font-family: Maersk-Headline">MDS Community Library (SCM)</p>
        <img src="@assets/ui-1.svg" alt="" class="mt-5 mx-auto mb-4" />
        <p>Core functionality library of UI components for SCM Platform. The aim is to provide a common, consistent and proficient solution for all the SCM apps across the platform.</p>
      </div>
    </div>

    <p class="mt-16 text-00243D text-4xl" style="font-family: Maersk-Headline-Light">Need help?</p>
    <p class="text-545454 mt-4">In case of queries, comments, feedback please reach out.</p>
    <div class=" mt-8"><a href="mailto:shubham.kumar@maersk.com?cc=joji.george@maersk.com" class="bg-00243D text-white py-2 px-4 rounded-md">Get in touch</a></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { uiComponents, maerskDesignSystem, apiRegistration } from '@/AppConfig'
import NavigationUtils from '@utils/Navigation';

export default {
  data() {
    return {
      apiRegistration,
      maerskDesignSystem,
      uiComponents,
      NavigationUtils,
    }
  },
};
</script>