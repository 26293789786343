<template>
  <div>
    <div class="loader" v-if="!loaded">
      <loader class="mx-auto" :loading="true" />
    </div>
    <iframe v-bind:class="containTabs === true ? `tabbed-dashboard ` : `dashboard`" v-bind:src="dashboardUrl" frameborder="0" width="100%" v-bind:onload="onLoad"></iframe>
  </div>
</template>

<script lang="ts">
import { computed, watch } from '@vue/runtime-core';
import { ref } from 'vue';
import Loader from '@/components/loader/Loader.vue';
import { qaPortal } from '../../AppConfig';

export default {
  props: {
    dashboardId: String,
    containTabs: Boolean,
  },
  components: {
    Loader,
  },
  setup(props) {
    const loaded = ref(false);

    const dashboardUrl = computed(
      () => `${qaPortal}`
    );

    const onLoad = () => {
      loaded.value = true;
      console.log('obj?.contentWindow')
    };

    watch(
      () => props.dashboardId,
      () => {
        loaded.value = false;
      }
    );

    return {
      dashboardUrl,
      onLoad,
      loaded,
    };
  },
};
</script>

<style scoped>
.dashboard {
  height: calc(100vh - 70px);
  box-sizing: border-box;
}

.tabbed-dashboard {
  height: calc(100vh - 125px);
  box-sizing: border-box;
}

.loader {
  height: calc(100vh);
}
</style>
