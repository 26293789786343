<template>
  <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
    <span class="block sm:inline">{{ error }} : {{ errorClass }}</span>
    <span class="block mt-4">{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts">
export default {
  props: ['error', 'errorClass', 'errorMessage'],
};
</script>
