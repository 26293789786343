<template>
  <div>
    <loader class="mx-auto" :loading="!loaded" />
    <iframe v-bind:hidden="!loaded" v-bind:class="containTabs === true ? `tabbed-dashboard ` : `dashboard`" v-bind:src="dashboardUrl" frameborder="0" width="100%" v-bind:onload="onLoad"></iframe>
  </div>
</template>

<script lang="ts">
import { computed, onMounted, watch } from '@vue/runtime-core';
import { ref } from 'vue';
import Loader from '@/components/loader/Loader.vue';
import { supersetUrl } from '../../AppConfig';
import SupersetDashboardService from './SupersetDashboardService';

export default {
  props: {
    dashboardId: String,
    containTabs: Boolean,
  },
  components: {
    Loader,
  },
  setup(props) {
    const service = new SupersetDashboardService();

    const dashboardUrl = ref<String>('');

    const loaded = ref(false);

    onMounted(async () => {
      await service.getViewerToken(props.dashboardId);
      const nextUrl = `${supersetUrl}/superset/dashboard/${props.dashboardId}/?standalone=true`;
      dashboardUrl.value = `${supersetUrl}/login/?next=${nextUrl}&token=${service.token.value}`;
    });

    const onLoad = () => {
      if (dashboardUrl.value != '') loaded.value = true;
    };

    watch(
      () => props.dashboardId,
      () => {
        loaded.value = false;
      }
    );

    return {
      dashboardUrl,
      onLoad,
      loaded,
    };
  },
};
</script>

<style scoped>
.dashboard {
  height: calc(100vh - 70px);
  box-sizing: border-box;
}

.tabbed-dashboard {
  height: calc(100vh - 125px);
  box-sizing: border-box;
}

.loader {
  height: calc(100vh);
}
</style>
