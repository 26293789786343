export type CapabilityListData = {
    id: string,
    name: string,
    description: string,
    services: Service[]
}

export type Service = {
    id: string,
    name: string
}

export enum Menu {
    NSCPTracer,
    Welcome,
    Api,
    Home,
    DataQuality,
    PlatformSearch,
    DataExplorer
}