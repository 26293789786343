import SGService from "@/service/SGService";
import { ref } from "vue";
import { Buffer } from 'buffer'

class SupersetDashboardService {

    token = ref<String>(undefined)

    tokenLoading = ref(false)

    error = ref<string>(undefined)

    async getViewerToken(dashboardId: String) {
        this.error.value = undefined;
        this.token.value = undefined;
        this.tokenLoading.value = true;
        try {
            const token = localStorage.getItem('dashboard.token')
            if (token && !this.isTokenExpired(token)) {
                this.token.value = token
            } else {
                this.token.value = (await SGService.Instance.getDashboardViewerToken(dashboardId))?.token;
            }
        } catch (error) {
            console.log(error)
            this.error.value = `Failed to fetch authentication token`;
        }
        this.tokenLoading.value = false;
    }

    isTokenExpired(token: String) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return Date.now() > JSON.parse(jsonPayload).exp * 1000
    }
}

export default SupersetDashboardService