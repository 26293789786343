import Home from '../pages/index.vue';
import DeveloperHubHome from '../pages/developer-hub/index.vue';
import Dashboard from '../pages/dashboards/customer-statistics/index.vue';
import EngineeringMetricsDashboard from '../pages/dashboards/engineering-metrics/index.vue';
import PlatFormHealth from '../pages/platform-health/index.vue';
import PlatFormHealthv2 from '../pages/platform-health-v2/index.vue';
import TrendsDashboard from '../pages/dashboards/trends/index.vue';
import GlobalSearchDashboard from '../pages/dashboards/global-search/index.vue';
import MiscAnalyticsDashboard from '../pages/dashboards/misc-analytics/index.vue';
import UserAnalyticsDashboard from '../pages/dashboards/user-analytics/index.vue';
import NotFound from '../pages/not-found/index.vue'
import EventStatisticsDashboard from '../pages/dashboards/event-statistics/index.vue'
import MPRDashboard from '../pages/dashboards/mpr-statistics/index.vue'

const ROUTER_PATH = {
    Home: {
        name: 'Home',
        path: '/',
        component: Home,
    },
    Dashboardsv1: {
        name: 'Dashboardsv1',
        path: '/dashboards',
        redirect: '/dashboards/customer-statistics'
    },
    Dashboard: {
        name: 'Dashboard',
        path: '/dashboards/customer-statistics',
        component: Dashboard,
    },
    EventStatistics: {
        name: 'EventStatistics',
        path: '/dashboards/events-statistics',
        component: EventStatisticsDashboard,
    },
    MPRStatistics: {
        name: 'MPRStatistics',
        path: '/dashboards/mpr-statistics',
        component: MPRDashboard,
    },
    EngineeringMetricsDashboardv1: {
        name: 'EngineeringMetricsDashboardv1',
        path: '/engineering-metrics',
        redirect: '/dashboards/engineering-metrics',
    },
    EngineeringMetricsDashboard: {
        name: 'EngineeringMetricsDashboard',
        path: '/dashboards/engineering-metrics',
        component: EngineeringMetricsDashboard,
    },
    TrendsDashboard: {
        name: 'TrendsDashboard',
        path: '/dashboards/trends',
        component: TrendsDashboard,
    },
    GlobalSearchDashboard: {
        name: 'GlobalSearchDashboard',
        path: '/dashboards/global-search',
        component: GlobalSearchDashboard,
    },
    MiscAnalyticsDashboard: {
        name: 'MiscAnalyticsDashboard',
        path: '/dashboards/misc-analytics',
        component: MiscAnalyticsDashboard,
    },
    UserAnalyticsDashboard: {
        name: 'UserAnalyticsDashboard',
        path: '/dashboards/user-analytics',
        component: UserAnalyticsDashboard,
    },
    PlatFormHealth: {
        name: 'PlatFormHealth',
        path: '/platform-health',
        component: PlatFormHealthv2,
    },
    DeveloperHubHome: {
        path: '/developer-hub',
        name: 'DeveloperHubHome',
        component: DeveloperHubHome,
    },
    DeveloperHubTools: {
        path: '/developer-hub/:tool',
        name: 'DeveloperHubHome',
        component: DeveloperHubHome,
    },
    NotFound: {
        path: '/:pathMatch(.*)*',
        component: NotFound
    },
    Assets: {
        path: '/assets',
        name: 'Assets',
        component: NotFound,
    },
};

export default ROUTER_PATH;