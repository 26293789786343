declare global {
    // eslint-disable-next-line no-unused-vars
    interface String {
        // eslint-disable-next-line no-unused-vars
        format(data: object): string;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.format = function format(data: object) {
    const url = String(this);
    const regex = /:(\w+)/g;
    return url.replace(regex, (_match, p1) => data[p1] || `:${p1}`);
};

export { };
